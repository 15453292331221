import httpService from '../../../http/implementations/httpService';
import batchRequestFactory from '../../../services/batchRequestService/batchRequestFactory';
import {
  getUsersUrl,
  DefaultUserBatchSize,
  DefaultUserProcessBatchWaitTime,
  UserObject
} from './userDataConstants';

const userBatchRequestProcessor = batchRequestFactory.createRequestProcessor(
  items => {
    const urlConfig = {
      url: getUsersUrl(),
      retryable: true,
      withCredentials: true
    };
    const userIds = items.map(({ itemId }) => itemId);
    return httpService
      .post(urlConfig, { userIds, excludeBannedUsers: true })
      .then(({ data: { data: users } }) => {
        const results: { [key: number]: UserObject } = {};
        users.forEach((user: UserObject) => {
          results[user.id] = user;
        });
        return results;
      });
  },
  id => id?.toString(),
  {
    batchSize: DefaultUserBatchSize,
    processBatchWaitTime: DefaultUserProcessBatchWaitTime
  }
);

export default userBatchRequestProcessor;
