import angularJsUtilitiesModule from "../angularJsUtilitiesModule";
import { GameLauncher } from "Roblox";

function playGameService($log, eventStreamService) {
    "ngInject";
    function sendEventStream(eventStreamProperties) {
        var eventName = eventStreamProperties.eventName;
        var ctx = eventStreamProperties.ctx;
        var additionalProperties = eventStreamProperties.properties;
        eventStreamService.sendEventWithTarget(eventName, ctx, additionalProperties);
    }
    
    function sendGamePlayIntentEvent(ctx,rootPlaceId) {
        eventStreamService.sendGamePlayEvent(ctx, rootPlaceId);
    }
        
    function joinGameInstance(placeId, gameInstanceId) {
        GameLauncher.joinGameInstance(placeId, gameInstanceId, true, true);
    }
        
    function followPlayer(playerId) {
        GameLauncher.followPlayerIntoGame(playerId);
    }
        
    function joinMultiPlayer(placeId) {
        GameLauncher.joinMultiplayerGame(placeId);
    }
    return {
        launchGame: function (playGameProperties, eventStreamProperties) {
            if (GameLauncher) {
                var rootPlaceId = playGameProperties.rootPlaceId;
                var placeId = playGameProperties.placeId;
                var gameInstanceId = playGameProperties.gameInstanceId;
                var playerId = playGameProperties.playerId;
                if (placeId === rootPlaceId && gameInstanceId){
                    eventStreamProperties.properties.gameInstanceId = gameInstanceId;
                    sendEventStream(eventStreamProperties);
                    sendGamePlayIntentEvent(eventStreamProperties.gamePlayIntentEventCtx, rootPlaceId);
                    joinGameInstance(placeId, gameInstanceId);
                } else if (rootPlaceId && playerId) {
                    eventStreamProperties.properties.playerId = playerId;
                    sendEventStream(eventStreamProperties);
                    sendGamePlayIntentEvent(eventStreamProperties.gamePlayIntentEventCtx, rootPlaceId);
                    followPlayer(playerId);
                } else {
                    sendEventStream(eventStreamProperties);
                    sendGamePlayIntentEvent(eventStreamProperties.gamePlayIntentEventCtx, placeId);
                    joinMultiPlayer(placeId);
                }
            }
        },
        
        buildPlayGameProperties: function (rootPlaceId, placeId, gameInstanceId, playerId) {
            return {
                rootPlaceId: rootPlaceId,
                placeId: placeId,
                gameInstanceId: gameInstanceId,
                playerId: playerId
            }
        }
    }
};

angularJsUtilitiesModule.factory("playGameService", playGameService);

export default playGameService;