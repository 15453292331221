export const DefaultProcessBatchWaitTime = 10;

export const DefaultExpirationWindowMS = 30000; // 30s

export const DefaultCooldown = 1000;

export const DefaultMaxRetryAttempts = 2;

export const DefaultBatchSize = 100;

export const DefaultCacheProperties = {
  useCache: false,
  expirationWindowMS: DefaultExpirationWindowMS
};

export enum BatchRequestError {
  processFailure = 'processFailure',
  unretriableFailure = 'unretriableFailure',
  maxAttemptsReached = 'maxAttemptsReached'
}

export type CacheObject = {
  data: any;
};

export type BatchIdSerializer = (id: number) => string;

export type BatchItemProcessor = (ids: Array<QueueItem>) => Promise<ItemProcessorResult>;

export type FailureCooldownProcessor = (attempts: number) => number;

export type ItemExpirationProcessor = (key: string) => any;

export interface CacheProperties {
  useCache?: boolean;
  expirationWindowMS?: number;
  refreshCache?: boolean;
}

export interface BatchRequestProperties {
  getFailureCooldown?: FailureCooldownProcessor;
  maxRetryAttempts?: number;
  batchSize: number;
  processBatchWaitTime?: number;
  getItemExpiration?: ItemExpirationProcessor;
  cacheProperties?: CacheProperties;
}

export interface QueueItem {
  key: string;
  itemId: number;
  retryAttempts: number;
  queueAfter: number;
  startTime: Date;
  cacheProperties: CacheProperties;
  resolve: QueueResolver;
  reject: QueueRejecter;
}

export type QueueResolver = (item: any) => any;
export type QueueRejecter = (error: BatchRequestError) => any;

export type ItemProcessorResult = { [key: string]: any };

export interface BatchRequestFactory {}

export const CacheStorePrefix = 'CacheStore:BatchRequestProcessor:';
