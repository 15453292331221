import {
  BatchRequestProperties,
  BatchIdSerializer,
  BatchItemProcessor,
  DefaultProcessBatchWaitTime,
  DefaultMaxRetryAttempts,
  DefaultCacheProperties
} from './batchRequestConstants';
import { createExponentialBackoffCooldown } from './batchRequestUtil';
import BatchRequestProcessor from './batchRequestProcessor';

class BatchRequestProcessorFactory {
  public readonly createExponentialBackoffCooldown = createExponentialBackoffCooldown;

  createRequestProcessor(
    itemsProcessor: BatchItemProcessor,
    itemSerializer: BatchIdSerializer,
    properties: BatchRequestProperties
  ) {
    if (!properties.processBatchWaitTime) {
      properties.processBatchWaitTime = DefaultProcessBatchWaitTime;
    }
    if (!properties.maxRetryAttempts) {
      properties.maxRetryAttempts = DefaultMaxRetryAttempts;
    }
    if (!properties.cacheProperties) {
      properties.cacheProperties = DefaultCacheProperties;
    }

    return new BatchRequestProcessor(itemsProcessor, itemSerializer, properties);
  }
}

export default new BatchRequestProcessorFactory();
