import { AxiosPromise } from 'axios';
import * as ThumbnailsProvider from '../../providers/thumbnails/thumbnailsProvider';

const gameThumbnailsApiInstance = new ThumbnailsProvider.GamesApi();
const gamePassesApiInstance = new ThumbnailsProvider.GamePassesApi();

enum ReturnPolicy {
  PlaceHolder = 'PlaceHolder',
  AutoGenerated = 'AutoGenerated',
  ForceAutoGenerated = 'ForceAutoGenerated'
}

enum Size {
  Large = '150x150',
  Default = '50x50'
}

enum GameThumbnailSize {
  width768 = '768x432',
  width576 = '576x324',
  width480 = '480x270',
  width384 = '384x216',
  width256 = '256x144'
}

enum FileType {
  Png = 'Png'
}

const getIcons = (
  universeIds: Array<number>,
  returnPolicy?: ReturnPolicy,
  size?: Size,
  format?: number,
  isCircular?: boolean
): AxiosPromise<ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> => {
  return gameThumbnailsApiInstance.v1GamesIconsGet(
    universeIds,
    returnPolicy,
    size,
    format,
    isCircular,
    { withCredentials: true }
  );
};

const getGameThumbnails = (
  universeIds: Array<number>,
  countPerUniverse?: number,
  defaults?: boolean,
  size?: GameThumbnailSize,
  format?: number,
  isCircular?: boolean
): AxiosPromise<ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse> => {
  return gameThumbnailsApiInstance.v1GamesMultigetThumbnailsGet(
    universeIds,
    countPerUniverse,
    defaults,
    size,
    format,
    isCircular,
    { withCredentials: true }
  );
};

const getGamePassIcons = (
  gamePassIds: Array<number>,
  size?: '150x150',
  format?: number,
  isCircular?: boolean
): AxiosPromise<ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> => {
  return gamePassesApiInstance.v1GamePassesGet(gamePassIds, size, format, isCircular, {
    withCredentials: true
  });
};

const getThumbnailsByThumbnailIds = (
  universeId: number,
  thumbnailIds: Array<number>,
  size?: GameThumbnailSize,
  format?: number,
  isCircular?: boolean
): AxiosPromise<ThumbnailsProvider.RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse> => {
  return gameThumbnailsApiInstance.v1GamesUniverseIdThumbnailsGet(
    universeId,
    thumbnailIds,
    size,
    format,
    isCircular,
    {
      withCredentials: true
    }
  );
};
export default {
  getIcons,
  getGameThumbnails,
  getGamePassIcons,
  getThumbnailsByThumbnailIds,
  ReturnPolicy,
  Size,
  GameThumbnailSize,
  FileType
};
