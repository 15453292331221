// tslint:disable
/**
 * Thumbnails Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import { AxiosPromise, AxiosInstance } from 'axios';
import { httpClient as globalAxios } from '../../../http/http';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * A response model for thumbnails which belong to a specific universe ID
 * @export
 * @interface RobloxThumbnailsApiModelsUniverseThumbnailsResponse
 */
export interface RobloxThumbnailsApiModelsUniverseThumbnailsResponse {
  /**
   * Integer universe ID
   * @type {number}
   * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
   */
  universeId?: number;
  /**
   *
   * @type {RobloxWebWebAPIModelsApiErrorModel}
   * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
   */
  error?: RobloxWebWebAPIModelsApiErrorModel;
  /**
   * An array of ThumbnailResponse objects
   * @type {Array<RobloxWebResponsesThumbnailsThumbnailResponse>}
   * @memberof RobloxThumbnailsApiModelsUniverseThumbnailsResponse
   */
  thumbnails?: Array<RobloxWebResponsesThumbnailsThumbnailResponse>;
}
/**
 *
 * @export
 * @interface RobloxThumbnailsApisModelsThumbnailBatchRequest
 */
export interface RobloxThumbnailsApisModelsThumbnailBatchRequest {
  /**
   * The request id. (Generated client side, used to represent the items in the request)
   * @type {string}
   * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
   */
  requestId?: string;
  /**
   * The thumbnail target id
   * @type {number}
   * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
   */
  targetId?: number;
  /**
   * The type of the thumbnails
   * @type {string}
   * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
   */
  type?: RobloxThumbnailsApisModelsThumbnailBatchRequestTypeEnum;
  /**
   * The thumbnail size
   * @type {string}
   * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
   */
  size?: string;
  /**
   * Is the thumbnail circular shape
   * @type {boolean}
   * @memberof RobloxThumbnailsApisModelsThumbnailBatchRequest
   */
  isCircular?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxThumbnailsApisModelsThumbnailBatchRequestTypeEnum {
  Avatar = 'Avatar',
  AvatarHeadShot = 'AvatarHeadShot',
  GameIcon = 'GameIcon',
  BadgeIcon = 'BadgeIcon',
  GameThumbnail = 'GameThumbnail',
  GamePass = 'GamePass',
  Asset = 'Asset',
  BundleThumbnail = 'BundleThumbnail',
  Outfit = 'Outfit',
  GroupIcon = 'GroupIcon',
  DeveloperProduct = 'DeveloperProduct'
}

/**
 *
 * @export
 * @interface RobloxThumbsImageParameters
 */
export interface RobloxThumbsImageParameters {
  /**
   *
   * @type {number}
   * @memberof RobloxThumbsImageParameters
   */
  Width?: number;
  /**
   *
   * @type {number}
   * @memberof RobloxThumbsImageParameters
   */
  Height?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxThumbsImageParameters
   */
  Format?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxThumbsImageParameters
   */
  ThumbnailFormatID?: number;
  /**
   *
   * @type {boolean}
   * @memberof RobloxThumbsImageParameters
   */
  IsCircular?: boolean;
  /**
   *
   * @type {string}
   * @memberof RobloxThumbsImageParameters
   */
  FileExtension?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxThumbsImageParameters
   */
  MimeType?: string;
}
/**
 *
 * @export
 * @interface RobloxWebResponsesThumbnailsThumbnailBatchResponse
 */
export interface RobloxWebResponsesThumbnailsThumbnailBatchResponse {
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  requestId?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  errorCode?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  errorMessage?: string;
  /**
   *
   * @type {number}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  targetId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  state?: RobloxWebResponsesThumbnailsThumbnailBatchResponseStateEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  imageUrl?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxWebResponsesThumbnailsThumbnailBatchResponseStateEnum {
  Error = 'Error',
  Completed = 'Completed',
  InReview = 'InReview',
  Pending = 'Pending',
  Blocked = 'Blocked'
}

/**
 *
 * @export
 * @interface RobloxWebResponsesThumbnailsThumbnailResponse
 */
export interface RobloxWebResponsesThumbnailsThumbnailResponse {
  /**
   *
   * @type {number}
   * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
   */
  targetId?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
   */
  state?: RobloxWebResponsesThumbnailsThumbnailResponseStateEnum;
  /**
   *
   * @type {string}
   * @memberof RobloxWebResponsesThumbnailsThumbnailResponse
   */
  imageUrl?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum RobloxWebResponsesThumbnailsThumbnailResponseStateEnum {
  Error = 'Error',
  Completed = 'Completed',
  InReview = 'InReview',
  Pending = 'Pending',
  Blocked = 'Blocked'
}

/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse {
  /**
   *
   * @type {Array<RobloxThumbnailsApiModelsUniverseThumbnailsResponse>}
   * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
   */
  data?: Array<RobloxThumbnailsApiModelsUniverseThumbnailsResponse>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse {
  /**
   *
   * @type {Array<RobloxWebResponsesThumbnailsThumbnailBatchResponse>}
   * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
   */
  data?: Array<RobloxWebResponsesThumbnailsThumbnailBatchResponse>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse {
  /**
   *
   * @type {Array<RobloxWebResponsesThumbnailsThumbnailResponse>}
   * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
   */
  data?: Array<RobloxWebResponsesThumbnailsThumbnailResponse>;
}
/**
 *
 * @export
 * @interface RobloxWebWebAPIModelsApiErrorModel
 */
export interface RobloxWebWebAPIModelsApiErrorModel {
  /**
   *
   * @type {number}
   * @memberof RobloxWebWebAPIModelsApiErrorModel
   */
  code?: number;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiErrorModel
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiErrorModel
   */
  userFacingMessage?: string;
  /**
   *
   * @type {string}
   * @memberof RobloxWebWebAPIModelsApiErrorModel
   */
  field?: string;
  /**
   *
   * @type {object}
   * @memberof RobloxWebWebAPIModelsApiErrorModel
   */
  fieldData?: object;
}

/**
 * AssetsApi - axios parameter creator
 * @export
 */
export const AssetsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails assets.
     * @param {Array<number>} assetIds The asset ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetsGet: async (
      assetIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'assetIds' is not null or undefined
      if (assetIds === null || assetIds === undefined) {
        throw new RequiredError(
          'assetIds',
          'Required parameter assetIds was null or undefined when calling v1AssetsGet.'
        );
      }
      const localVarPath = `/v1/assets`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (assetIds) {
        localVarQueryParameter['assetIds'] = assetIds.join(COLLECTION_FORMATS.csv);
      }

      if (returnPolicy !== undefined) {
        localVarQueryParameter['returnPolicy'] = returnPolicy;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AssetsApi - functional programming interface
 * @export
 */
export const AssetsApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails assets.
     * @param {Array<number>} assetIds The asset ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1AssetsGet(
      assetIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await AssetsApiAxiosParamCreator(configuration).v1AssetsGet(
        assetIds,
        returnPolicy,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * AssetsApi - factory interface
 * @export
 */
export const AssetsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Thumbnails assets.
     * @param {Array<number>} assetIds The asset ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1AssetsGet(
      assetIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return AssetsApiFp(configuration)
        .v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * AssetsApi - interface
 * @export
 * @interface AssetsApi
 */
export interface AssetsApiInterface {
  /**
   *
   * @summary Thumbnails assets.
   * @param {Array<number>} assetIds The asset ids.
   * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApiInterface
   */
  v1AssetsGet(
    assetIds: Array<number>,
    returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * AssetsApi - object-oriented interface
 * @export
 * @class AssetsApi
 * @extends {BaseAPI}
 */
export class AssetsApi extends BaseAPI implements AssetsApiInterface {
  /**
   *
   * @summary Thumbnails assets.
   * @param {Array<number>} assetIds The asset ids.
   * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting thumbnail to return (default &#x3D; PlaceHolder).
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AssetsApi
   */
  public v1AssetsGet(
    assetIds: Array<number>,
    returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return AssetsApiFp(this.configuration)
      .v1AssetsGet(assetIds, returnPolicy, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * AvatarApi - axios parameter creator
 * @export
 */
export const AvatarApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Avatar Full body shots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
     * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersAvatarGet: async (
      userIds: Array<number>,
      size?:
        | '30x30'
        | '48x48'
        | '60x60'
        | '75x75'
        | '100x100'
        | '110x110'
        | '140x140'
        | '150x150'
        | '150x200'
        | '180x180'
        | '250x250'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userIds' is not null or undefined
      if (userIds === null || userIds === undefined) {
        throw new RequiredError(
          'userIds',
          'Required parameter userIds was null or undefined when calling v1UsersAvatarGet.'
        );
      }
      const localVarPath = `/v1/users/avatar`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userIds) {
        localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get Avatar Headshots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'48x48' | '50x50' | '60x60' | '75x75' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersAvatarHeadshotGet: async (
      userIds: Array<number>,
      size?:
        | '48x48'
        | '50x50'
        | '60x60'
        | '75x75'
        | '110x110'
        | '150x150'
        | '180x180'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userIds' is not null or undefined
      if (userIds === null || userIds === undefined) {
        throw new RequiredError(
          'userIds',
          'Required parameter userIds was null or undefined when calling v1UsersAvatarHeadshotGet.'
        );
      }
      const localVarPath = `/v1/users/avatar-headshot`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userIds) {
        localVarQueryParameter['userIds'] = userIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AvatarApi - functional programming interface
 * @export
 */
export const AvatarApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get Avatar Full body shots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
     * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1UsersAvatarGet(
      userIds: Array<number>,
      size?:
        | '30x30'
        | '48x48'
        | '60x60'
        | '75x75'
        | '100x100'
        | '110x110'
        | '140x140'
        | '150x150'
        | '150x200'
        | '180x180'
        | '250x250'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await AvatarApiAxiosParamCreator(configuration).v1UsersAvatarGet(
        userIds,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Get Avatar Headshots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'48x48' | '50x50' | '60x60' | '75x75' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1UsersAvatarHeadshotGet(
      userIds: Array<number>,
      size?:
        | '48x48'
        | '50x50'
        | '60x60'
        | '75x75'
        | '110x110'
        | '150x150'
        | '180x180'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await AvatarApiAxiosParamCreator(
        configuration
      ).v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * AvatarApi - factory interface
 * @export
 */
export const AvatarApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get Avatar Full body shots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
     * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersAvatarGet(
      userIds: Array<number>,
      size?:
        | '30x30'
        | '48x48'
        | '60x60'
        | '75x75'
        | '100x100'
        | '110x110'
        | '140x140'
        | '150x150'
        | '150x200'
        | '180x180'
        | '250x250'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return AvatarApiFp(configuration)
        .v1UsersAvatarGet(userIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get Avatar Headshots for the given CSV of userIds
     * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
     * @param {'48x48' | '50x50' | '60x60' | '75x75' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersAvatarHeadshotGet(
      userIds: Array<number>,
      size?:
        | '48x48'
        | '50x50'
        | '60x60'
        | '75x75'
        | '110x110'
        | '150x150'
        | '180x180'
        | '352x352'
        | '420x420'
        | '720x720',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return AvatarApiFp(configuration)
        .v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * AvatarApi - interface
 * @export
 * @interface AvatarApi
 */
export interface AvatarApiInterface {
  /**
   *
   * @summary Get Avatar Full body shots for the given CSV of userIds
   * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
   * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApiInterface
   */
  v1UsersAvatarGet(
    userIds: Array<number>,
    size?:
      | '30x30'
      | '48x48'
      | '60x60'
      | '75x75'
      | '100x100'
      | '110x110'
      | '140x140'
      | '150x150'
      | '150x200'
      | '180x180'
      | '250x250'
      | '352x352'
      | '420x420'
      | '720x720',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;

  /**
   *
   * @summary Get Avatar Headshots for the given CSV of userIds
   * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
   * @param {'48x48' | '50x50' | '60x60' | '75x75' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApiInterface
   */
  v1UsersAvatarHeadshotGet(
    userIds: Array<number>,
    size?:
      | '48x48'
      | '50x50'
      | '60x60'
      | '75x75'
      | '110x110'
      | '150x150'
      | '180x180'
      | '352x352'
      | '420x420'
      | '720x720',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * AvatarApi - object-oriented interface
 * @export
 * @class AvatarApi
 * @extends {BaseAPI}
 */
export class AvatarApi extends BaseAPI implements AvatarApiInterface {
  /**
   *
   * @summary Get Avatar Full body shots for the given CSV of userIds
   * @param {Array<number>} userIds CSV for the userIds to get avatar full body shots
   * @param {'30x30' | '48x48' | '60x60' | '75x75' | '100x100' | '110x110' | '140x140' | '150x150' | '150x200' | '180x180' | '250x250' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApi
   */
  public v1UsersAvatarGet(
    userIds: Array<number>,
    size?:
      | '30x30'
      | '48x48'
      | '60x60'
      | '75x75'
      | '100x100'
      | '110x110'
      | '140x140'
      | '150x150'
      | '150x200'
      | '180x180'
      | '250x250'
      | '352x352'
      | '420x420'
      | '720x720',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return AvatarApiFp(this.configuration)
      .v1UsersAvatarGet(userIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get Avatar Headshots for the given CSV of userIds
   * @param {Array<number>} userIds CSV for the userIds to get avatar headshots
   * @param {'48x48' | '50x50' | '60x60' | '75x75' | '110x110' | '150x150' | '180x180' | '352x352' | '420x420' | '720x720'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AvatarApi
   */
  public v1UsersAvatarHeadshotGet(
    userIds: Array<number>,
    size?:
      | '48x48'
      | '50x50'
      | '60x60'
      | '75x75'
      | '110x110'
      | '150x150'
      | '180x180'
      | '352x352'
      | '420x420'
      | '720x720',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return AvatarApiFp(this.configuration)
      .v1UsersAvatarHeadshotGet(userIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BadgesApi - axios parameter creator
 * @export
 */
export const BadgesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails badge icons.
     * @param {Array<number>} badgeIds The badge ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BadgesIconsGet: async (
      badgeIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'badgeIds' is not null or undefined
      if (badgeIds === null || badgeIds === undefined) {
        throw new RequiredError(
          'badgeIds',
          'Required parameter badgeIds was null or undefined when calling v1BadgesIconsGet.'
        );
      }
      const localVarPath = `/v1/badges/icons`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (badgeIds) {
        localVarQueryParameter['badgeIds'] = badgeIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BadgesApi - functional programming interface
 * @export
 */
export const BadgesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails badge icons.
     * @param {Array<number>} badgeIds The badge ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BadgesIconsGet(
      badgeIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await BadgesApiAxiosParamCreator(configuration).v1BadgesIconsGet(
        badgeIds,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * BadgesApi - factory interface
 * @export
 */
export const BadgesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Thumbnails badge icons.
     * @param {Array<number>} badgeIds The badge ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BadgesIconsGet(
      badgeIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return BadgesApiFp(configuration)
        .v1BadgesIconsGet(badgeIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * BadgesApi - interface
 * @export
 * @interface BadgesApi
 */
export interface BadgesApiInterface {
  /**
   *
   * @summary Thumbnails badge icons.
   * @param {Array<number>} badgeIds The badge ids.
   * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgesApiInterface
   */
  v1BadgesIconsGet(
    badgeIds: Array<number>,
    size?: '150x150',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * BadgesApi - object-oriented interface
 * @export
 * @class BadgesApi
 * @extends {BaseAPI}
 */
export class BadgesApi extends BaseAPI implements BadgesApiInterface {
  /**
   *
   * @summary Thumbnails badge icons.
   * @param {Array<number>} badgeIds The badge ids.
   * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BadgesApi
   */
  public v1BadgesIconsGet(
    badgeIds: Array<number>,
    size?: '150x150',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return BadgesApiFp(this.configuration)
      .v1BadgesIconsGet(badgeIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BatchApi - axios parameter creator
 * @export
 */
export const BatchApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns a list of thumbnails with varying types and sizes
     * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BatchPost: async (
      requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'requests' is not null or undefined
      if (requests === null || requests === undefined) {
        throw new RequiredError(
          'requests',
          'Required parameter requests was null or undefined when calling v1BatchPost.'
        );
      }
      const localVarPath = `/v1/batch`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      const needsSerialization =
        typeof requests !== 'string' ||
        localVarRequestOptions.headers['Content-Type'] === 'application/json';
      localVarRequestOptions.data = needsSerialization
        ? JSON.stringify(requests !== undefined ? requests : {})
        : requests || '';

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BatchApi - functional programming interface
 * @export
 */
export const BatchApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Returns a list of thumbnails with varying types and sizes
     * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BatchPost(
      requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
      >
    > {
      const localVarAxiosArgs = await BatchApiAxiosParamCreator(configuration).v1BatchPost(
        requests,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * BatchApi - factory interface
 * @export
 */
export const BatchApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Returns a list of thumbnails with varying types and sizes
     * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BatchPost(
      requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
    > {
      return BatchApiFp(configuration)
        .v1BatchPost(requests, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * BatchApi - interface
 * @export
 * @interface BatchApi
 */
export interface BatchApiInterface {
  /**
   *
   * @summary Returns a list of thumbnails with varying types and sizes
   * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchApiInterface
   */
  v1BatchPost(
    requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailBatchResponse
  >;
}

/**
 * BatchApi - object-oriented interface
 * @export
 * @class BatchApi
 * @extends {BaseAPI}
 */
export class BatchApi extends BaseAPI implements BatchApiInterface {
  /**
   *
   * @summary Returns a list of thumbnails with varying types and sizes
   * @param {Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>} requests List of ThumbnailBatchRequest objects
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BatchApi
   */
  public v1BatchPost(
    requests: Array<RobloxThumbnailsApisModelsThumbnailBatchRequest>,
    options?: any
  ) {
    return BatchApiFp(this.configuration)
      .v1BatchPost(requests, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * BundlesApi - axios parameter creator
 * @export
 */
export const BundlesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get bundle thumbnails for the given CSV of bundle ids
     * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesThumbnailsGet: async (
      bundleIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'bundleIds' is not null or undefined
      if (bundleIds === null || bundleIds === undefined) {
        throw new RequiredError(
          'bundleIds',
          'Required parameter bundleIds was null or undefined when calling v1BundlesThumbnailsGet.'
        );
      }
      const localVarPath = `/v1/bundles/thumbnails`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (bundleIds) {
        localVarQueryParameter['bundleIds'] = bundleIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * BundlesApi - functional programming interface
 * @export
 */
export const BundlesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get bundle thumbnails for the given CSV of bundle ids
     * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1BundlesThumbnailsGet(
      bundleIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await BundlesApiAxiosParamCreator(
        configuration
      ).v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * BundlesApi - factory interface
 * @export
 */
export const BundlesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get bundle thumbnails for the given CSV of bundle ids
     * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1BundlesThumbnailsGet(
      bundleIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return BundlesApiFp(configuration)
        .v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * BundlesApi - interface
 * @export
 * @interface BundlesApi
 */
export interface BundlesApiInterface {
  /**
   *
   * @summary Get bundle thumbnails for the given CSV of bundle ids
   * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundlesApiInterface
   */
  v1BundlesThumbnailsGet(
    bundleIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * BundlesApi - object-oriented interface
 * @export
 * @class BundlesApi
 * @extends {BaseAPI}
 */
export class BundlesApi extends BaseAPI implements BundlesApiInterface {
  /**
   *
   * @summary Get bundle thumbnails for the given CSV of bundle ids
   * @param {Array<number>} bundleIds CSV for the bundle ids to get bundle thumbnails
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BundlesApi
   */
  public v1BundlesThumbnailsGet(
    bundleIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return BundlesApiFp(this.configuration)
      .v1BundlesThumbnailsGet(bundleIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * DeveloperProductsApi - axios parameter creator
 * @export
 */
export const DeveloperProductsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails developer product icons.
     * @param {Array<number>} developerProductIds The developer product ids.
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1DeveloperProductsIconsGet: async (
      developerProductIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'developerProductIds' is not null or undefined
      if (developerProductIds === null || developerProductIds === undefined) {
        throw new RequiredError(
          'developerProductIds',
          'Required parameter developerProductIds was null or undefined when calling v1DeveloperProductsIconsGet.'
        );
      }
      const localVarPath = `/v1/developer-products/icons`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (developerProductIds) {
        localVarQueryParameter['developerProductIds'] = developerProductIds.join(
          COLLECTION_FORMATS.csv
        );
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DeveloperProductsApi - functional programming interface
 * @export
 */
export const DeveloperProductsApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails developer product icons.
     * @param {Array<number>} developerProductIds The developer product ids.
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1DeveloperProductsIconsGet(
      developerProductIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await DeveloperProductsApiAxiosParamCreator(
        configuration
      ).v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * DeveloperProductsApi - factory interface
 * @export
 */
export const DeveloperProductsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Thumbnails developer product icons.
     * @param {Array<number>} developerProductIds The developer product ids.
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1DeveloperProductsIconsGet(
      developerProductIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return DeveloperProductsApiFp(configuration)
        .v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * DeveloperProductsApi - interface
 * @export
 * @interface DeveloperProductsApi
 */
export interface DeveloperProductsApiInterface {
  /**
   *
   * @summary Thumbnails developer product icons.
   * @param {Array<number>} developerProductIds The developer product ids.
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperProductsApiInterface
   */
  v1DeveloperProductsIconsGet(
    developerProductIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * DeveloperProductsApi - object-oriented interface
 * @export
 * @class DeveloperProductsApi
 * @extends {BaseAPI}
 */
export class DeveloperProductsApi extends BaseAPI implements DeveloperProductsApiInterface {
  /**
   *
   * @summary Thumbnails developer product icons.
   * @param {Array<number>} developerProductIds The developer product ids.
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DeveloperProductsApi
   */
  public v1DeveloperProductsIconsGet(
    developerProductIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return DeveloperProductsApiFp(this.configuration)
      .v1DeveloperProductsIconsGet(developerProductIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GamePassesApi - axios parameter creator
 * @export
 */
export const GamePassesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails game pass icons.
     * @param {Array<number>} gamePassIds The game pass ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamePassesGet: async (
      gamePassIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'gamePassIds' is not null or undefined
      if (gamePassIds === null || gamePassIds === undefined) {
        throw new RequiredError(
          'gamePassIds',
          'Required parameter gamePassIds was null or undefined when calling v1GamePassesGet.'
        );
      }
      const localVarPath = `/v1/game-passes`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (gamePassIds) {
        localVarQueryParameter['gamePassIds'] = gamePassIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GamePassesApi - functional programming interface
 * @export
 */
export const GamePassesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Thumbnails game pass icons.
     * @param {Array<number>} gamePassIds The game pass ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1GamePassesGet(
      gamePassIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await GamePassesApiAxiosParamCreator(configuration).v1GamePassesGet(
        gamePassIds,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * GamePassesApi - factory interface
 * @export
 */
export const GamePassesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Thumbnails game pass icons.
     * @param {Array<number>} gamePassIds The game pass ids.
     * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamePassesGet(
      gamePassIds: Array<number>,
      size?: '150x150',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return GamePassesApiFp(configuration)
        .v1GamePassesGet(gamePassIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * GamePassesApi - interface
 * @export
 * @interface GamePassesApi
 */
export interface GamePassesApiInterface {
  /**
   *
   * @summary Thumbnails game pass icons.
   * @param {Array<number>} gamePassIds The game pass ids.
   * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamePassesApiInterface
   */
  v1GamePassesGet(
    gamePassIds: Array<number>,
    size?: '150x150',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * GamePassesApi - object-oriented interface
 * @export
 * @class GamePassesApi
 * @extends {BaseAPI}
 */
export class GamePassesApi extends BaseAPI implements GamePassesApiInterface {
  /**
   *
   * @summary Thumbnails game pass icons.
   * @param {Array<number>} gamePassIds The game pass ids.
   * @param {'150x150'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamePassesApi
   */
  public v1GamePassesGet(
    gamePassIds: Array<number>,
    size?: '150x150',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return GamePassesApiFp(this.configuration)
      .v1GamePassesGet(gamePassIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GamesApi - axios parameter creator
 * @export
 */
export const GamesApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
     * @param {Array<number>} universeIds The universe ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesIconsGet: async (
      universeIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'universeIds' is not null or undefined
      if (universeIds === null || universeIds === undefined) {
        throw new RequiredError(
          'universeIds',
          'Required parameter universeIds was null or undefined when calling v1GamesIconsGet.'
        );
      }
      const localVarPath = `/v1/games/icons`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (universeIds) {
        localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
      }

      if (returnPolicy !== undefined) {
        localVarQueryParameter['returnPolicy'] = returnPolicy;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Fetch game thumbnail URLs for a list of universe IDs.
     * @param {Array<number>} universeIds comma-delimited list of universe IDs
     * @param {number} [countPerUniverse] max number of thumbnails to return per universe
     * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesMultigetThumbnailsGet: async (
      universeIds: Array<number>,
      countPerUniverse?: number,
      defaults?: boolean,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'universeIds' is not null or undefined
      if (universeIds === null || universeIds === undefined) {
        throw new RequiredError(
          'universeIds',
          'Required parameter universeIds was null or undefined when calling v1GamesMultigetThumbnailsGet.'
        );
      }
      const localVarPath = `/v1/games/multiget/thumbnails`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (universeIds) {
        localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
      }

      if (countPerUniverse !== undefined) {
        localVarQueryParameter['countPerUniverse'] = countPerUniverse;
      }

      if (defaults !== undefined) {
        localVarQueryParameter['defaults'] = defaults;
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
     * @param {number} universeId
     * @param {Array<number>} thumbnailIds
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesUniverseIdThumbnailsGet: async (
      universeId: number,
      thumbnailIds: Array<number>,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'universeId' is not null or undefined
      if (universeId === null || universeId === undefined) {
        throw new RequiredError(
          'universeId',
          'Required parameter universeId was null or undefined when calling v1GamesUniverseIdThumbnailsGet.'
        );
      }
      // verify required parameter 'thumbnailIds' is not null or undefined
      if (thumbnailIds === null || thumbnailIds === undefined) {
        throw new RequiredError(
          'thumbnailIds',
          'Required parameter thumbnailIds was null or undefined when calling v1GamesUniverseIdThumbnailsGet.'
        );
      }
      const localVarPath = `/v1/games/{universeId}/thumbnails`.replace(
        `{${'universeId'}}`,
        encodeURIComponent(String(universeId))
      );
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (thumbnailIds) {
        localVarQueryParameter['thumbnailIds'] = thumbnailIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
     * @param {Array<number>} universeIds The universe ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1GamesIconsGet(
      universeIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await GamesApiAxiosParamCreator(configuration).v1GamesIconsGet(
        universeIds,
        returnPolicy,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Fetch game thumbnail URLs for a list of universe IDs.
     * @param {Array<number>} universeIds comma-delimited list of universe IDs
     * @param {number} [countPerUniverse] max number of thumbnails to return per universe
     * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1GamesMultigetThumbnailsGet(
      universeIds: Array<number>,
      countPerUniverse?: number,
      defaults?: boolean,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
      >
    > {
      const localVarAxiosArgs = await GamesApiAxiosParamCreator(
        configuration
      ).v1GamesMultigetThumbnailsGet(
        universeIds,
        countPerUniverse,
        defaults,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
     * @param {number} universeId
     * @param {Array<number>} thumbnailIds
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1GamesUniverseIdThumbnailsGet(
      universeId: number,
      thumbnailIds: Array<number>,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await GamesApiAxiosParamCreator(
        configuration
      ).v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
     * @param {Array<number>} universeIds The universe ids.
     * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
     * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesIconsGet(
      universeIds: Array<number>,
      returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
      size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return GamesApiFp(configuration)
        .v1GamesIconsGet(universeIds, returnPolicy, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetch game thumbnail URLs for a list of universe IDs.
     * @param {Array<number>} universeIds comma-delimited list of universe IDs
     * @param {number} [countPerUniverse] max number of thumbnails to return per universe
     * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesMultigetThumbnailsGet(
      universeIds: Array<number>,
      countPerUniverse?: number,
      defaults?: boolean,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
    > {
      return GamesApiFp(configuration)
        .v1GamesMultigetThumbnailsGet(
          universeIds,
          countPerUniverse,
          defaults,
          size,
          format,
          isCircular,
          options
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
     * @param {number} universeId
     * @param {Array<number>} thumbnailIds
     * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GamesUniverseIdThumbnailsGet(
      universeId: number,
      thumbnailIds: Array<number>,
      size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return GamesApiFp(configuration)
        .v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * GamesApi - interface
 * @export
 * @interface GamesApi
 */
export interface GamesApiInterface {
  /**
   *
   * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
   * @param {Array<number>} universeIds The universe ids.
   * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
   * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApiInterface
   */
  v1GamesIconsGet(
    universeIds: Array<number>,
    returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
    size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;

  /**
   *
   * @summary Fetch game thumbnail URLs for a list of universe IDs.
   * @param {Array<number>} universeIds comma-delimited list of universe IDs
   * @param {number} [countPerUniverse] max number of thumbnails to return per universe
   * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
   * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApiInterface
   */
  v1GamesMultigetThumbnailsGet(
    universeIds: Array<number>,
    countPerUniverse?: number,
    defaults?: boolean,
    size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxThumbnailsApiModelsUniverseThumbnailsResponse
  >;

  /**
   *
   * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
   * @param {number} universeId
   * @param {Array<number>} thumbnailIds
   * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApiInterface
   */
  v1GamesUniverseIdThumbnailsGet(
    universeId: number,
    thumbnailIds: Array<number>,
    size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI implements GamesApiInterface {
  /**
   *
   * @summary Fetches game icon URLs for a list of universes\' root places. Ids that do not correspond to a valid universe will be filtered out.
   * @param {Array<number>} universeIds The universe ids.
   * @param {'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated'} [returnPolicy] Optional policy to use in selecting game icon to return (default &#x3D; PlaceHolder).
   * @param {'50x50' | '128x128' | '150x150' | '256x256' | '512x512'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApi
   */
  public v1GamesIconsGet(
    universeIds: Array<number>,
    returnPolicy?: 'PlaceHolder' | 'AutoGenerated' | 'ForceAutoGenerated',
    size?: '50x50' | '128x128' | '150x150' | '256x256' | '512x512',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return GamesApiFp(this.configuration)
      .v1GamesIconsGet(universeIds, returnPolicy, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetch game thumbnail URLs for a list of universe IDs.
   * @param {Array<number>} universeIds comma-delimited list of universe IDs
   * @param {number} [countPerUniverse] max number of thumbnails to return per universe
   * @param {boolean} [defaults] true if defaults (if any) should be returned if no media exists
   * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApi
   */
  public v1GamesMultigetThumbnailsGet(
    universeIds: Array<number>,
    countPerUniverse?: number,
    defaults?: boolean,
    size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return GamesApiFp(this.configuration)
      .v1GamesMultigetThumbnailsGet(
        universeIds,
        countPerUniverse,
        defaults,
        size,
        format,
        isCircular,
        options
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Fetches game thumbnail URLs for a list of universes\' thumbnail ids. Ids that do not correspond to a valid thumbnail will be filtered out.
   * @param {number} universeId
   * @param {Array<number>} thumbnailIds
   * @param {'768x432' | '576x324' | '480x270' | '384x216' | '256x144'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GamesApi
   */
  public v1GamesUniverseIdThumbnailsGet(
    universeId: number,
    thumbnailIds: Array<number>,
    size?: '768x432' | '576x324' | '480x270' | '384x216' | '256x144',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return GamesApiFp(this.configuration)
      .v1GamesUniverseIdThumbnailsGet(universeId, thumbnailIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * GroupEmblemApi - axios parameter creator
 * @export
 */
export const GroupEmblemApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
     * @param {Array<number>} groupIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GroupsIconsGet: async (
      groupIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'groupIds' is not null or undefined
      if (groupIds === null || groupIds === undefined) {
        throw new RequiredError(
          'groupIds',
          'Required parameter groupIds was null or undefined when calling v1GroupsIconsGet.'
        );
      }
      const localVarPath = `/v1/groups/icons`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (groupIds) {
        localVarQueryParameter['groupIds'] = groupIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * GroupEmblemApi - functional programming interface
 * @export
 */
export const GroupEmblemApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
     * @param {Array<number>} groupIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1GroupsIconsGet(
      groupIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await GroupEmblemApiAxiosParamCreator(
        configuration
      ).v1GroupsIconsGet(groupIds, size, format, isCircular, options);
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * GroupEmblemApi - factory interface
 * @export
 */
export const GroupEmblemApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
     * @param {Array<number>} groupIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1GroupsIconsGet(
      groupIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return GroupEmblemApiFp(configuration)
        .v1GroupsIconsGet(groupIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * GroupEmblemApi - interface
 * @export
 * @interface GroupEmblemApi
 */
export interface GroupEmblemApiInterface {
  /**
   *
   * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
   * @param {Array<number>} groupIds
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupEmblemApiInterface
   */
  v1GroupsIconsGet(
    groupIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * GroupEmblemApi - object-oriented interface
 * @export
 * @class GroupEmblemApi
 * @extends {BaseAPI}
 */
export class GroupEmblemApi extends BaseAPI implements GroupEmblemApiInterface {
  /**
   *
   * @summary Fetches thumbnail URLs for a list of groups. Ids that do not correspond to groups will be filtered out.
   * @param {Array<number>} groupIds
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GroupEmblemApi
   */
  public v1GroupsIconsGet(
    groupIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return GroupEmblemApiFp(this.configuration)
      .v1GroupsIconsGet(groupIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}

/**
 * OutfitsApi - axios parameter creator
 * @export
 */
export const OutfitsApiAxiosParamCreator = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get outfits for the given CSV of userIds
     * @param {Array<number>} userOutfitIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersOutfitsGet: async (
      userOutfitIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options: any = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userOutfitIds' is not null or undefined
      if (userOutfitIds === null || userOutfitIds === undefined) {
        throw new RequiredError(
          'userOutfitIds',
          'Required parameter userOutfitIds was null or undefined when calling v1UsersOutfitsGet.'
        );
      }
      const localVarPath = `/v1/users/outfits`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (userOutfitIds) {
        localVarQueryParameter['userOutfitIds'] = userOutfitIds.join(COLLECTION_FORMATS.csv);
      }

      if (size !== undefined) {
        localVarQueryParameter['size'] = size;
      }

      if (format !== undefined) {
        localVarQueryParameter['format'] = format;
      }

      if (isCircular !== undefined) {
        localVarQueryParameter['isCircular'] = isCircular;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OutfitsApi - functional programming interface
 * @export
 */
export const OutfitsApiFp = function(configuration?: Configuration) {
  return {
    /**
     *
     * @summary Get outfits for the given CSV of userIds
     * @param {Array<number>} userOutfitIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1UsersOutfitsGet(
      userOutfitIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<
        RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
      >
    > {
      const localVarAxiosArgs = await OutfitsApiAxiosParamCreator(configuration).v1UsersOutfitsGet(
        userOutfitIds,
        size,
        format,
        isCircular,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url
        };
        return axios.request(axiosRequestArgs);
      };
    }
  };
};

/**
 * OutfitsApi - factory interface
 * @export
 */
export const OutfitsApiFactory = function(
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @summary Get outfits for the given CSV of userIds
     * @param {Array<number>} userOutfitIds
     * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
     * @param {number} [format] The thumbnail format
     * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1UsersOutfitsGet(
      userOutfitIds: Array<number>,
      size?: '150x150' | '420x420',
      format?: number,
      isCircular?: boolean,
      options?: any
    ): AxiosPromise<
      RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
    > {
      return OutfitsApiFp(configuration)
        .v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, options)
        .then(request => request(axios, basePath));
    }
  };
};

/**
 * OutfitsApi - interface
 * @export
 * @interface OutfitsApi
 */
export interface OutfitsApiInterface {
  /**
   *
   * @summary Get outfits for the given CSV of userIds
   * @param {Array<number>} userOutfitIds
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OutfitsApiInterface
   */
  v1UsersOutfitsGet(
    userOutfitIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ): AxiosPromise<
    RobloxWebWebAPIModelsApiArrayResponseRobloxWebResponsesThumbnailsThumbnailResponse
  >;
}

/**
 * OutfitsApi - object-oriented interface
 * @export
 * @class OutfitsApi
 * @extends {BaseAPI}
 */
export class OutfitsApi extends BaseAPI implements OutfitsApiInterface {
  /**
   *
   * @summary Get outfits for the given CSV of userIds
   * @param {Array<number>} userOutfitIds
   * @param {'150x150' | '420x420'} [size] The thumbnail size, formatted widthxheight
   * @param {number} [format] The thumbnail format
   * @param {boolean} [isCircular] The circle thumbnail output parameter, true or false
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OutfitsApi
   */
  public v1UsersOutfitsGet(
    userOutfitIds: Array<number>,
    size?: '150x150' | '420x420',
    format?: number,
    isCircular?: boolean,
    options?: any
  ) {
    return OutfitsApiFp(this.configuration)
      .v1UsersOutfitsGet(userOutfitIds, size, format, isCircular, options)
      .then(request => request(this.axios, this.basePath));
  }
}
