// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Games Api v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as globalImportUrl from 'url';
import { Configuration } from './configuration';
import { httpClient as globalAxios } from '../../../http/http';
import { AxiosPromise, AxiosInstance } from 'axios';
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * An create request for a vip server
 * @export
 * @interface RobloxGamesApiCreateVipServersRequest
 */
export interface RobloxGamesApiCreateVipServersRequest {
    /**
     * The name new vip server will be
     * @type {string}
     * @memberof RobloxGamesApiCreateVipServersRequest
     */
    name?: string;
    /**
     * the expected price of the vip server
     * @type {number}
     * @memberof RobloxGamesApiCreateVipServersRequest
     */
    expectedPrice?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiGameResponse
 */
export interface RobloxGamesApiGameResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiGameResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiGameResponse
     */
    name?: string;
    /**
     * 
     * @type {RobloxGamesApiPlaceResponse}
     * @memberof RobloxGamesApiGameResponse
     */
    rootPlace?: RobloxGamesApiPlaceResponse;
}
/**
 * Game favorite request model.
 * @export
 * @interface RobloxGamesApiModelsRequestGameFavoriteResponse
 */
export interface RobloxGamesApiModelsRequestGameFavoriteResponse {
    /**
     * Is it a favorite game.
     * @type {boolean}
     * @memberof RobloxGamesApiModelsRequestGameFavoriteResponse
     */
    isFavorited?: boolean;
}
/**
 * Game favorite request model.
 * @export
 * @interface RobloxGamesApiModelsRequestGameFavoritesRequest
 */
export interface RobloxGamesApiModelsRequestGameFavoritesRequest {
    /**
     * true for favor the game, false for unfavor the game.
     * @type {boolean}
     * @memberof RobloxGamesApiModelsRequestGameFavoritesRequest
     */
    isFavorited?: boolean;
}
/**
 * Game recommendations request model.
 * @export
 * @interface RobloxGamesApiModelsRequestGameRecommendationsRequest
 */
export interface RobloxGamesApiModelsRequestGameRecommendationsRequest {
    /**
     * The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGameRecommendationsRequest
     */
    paginationKey?: string;
    /**
     * The requested number of rows.
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGameRecommendationsRequest
     */
    maxRows?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsRequestGameSortsRequest
 */
export interface RobloxGamesApiModelsRequestGameSortsRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGameSortsRequest
     */
    gameSortsContext?: RobloxGamesApiModelsRequestGameSortsRequestGameSortsContextEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGamesApiModelsRequestGameSortsRequestGameSortsContextEnum {
    GamesDefaultSorts = 'GamesDefaultSorts',
    GamesAllSorts = 'GamesAllSorts',
    HomeSorts = 'HomeSorts',
    ChatSorts = 'ChatSorts',
    UnifiedHomeSorts = 'UnifiedHomeSorts',
    AbTestSorts = 'AbTestSorts',
    GamesPageAbTestSorts1 = 'GamesPageAbTestSorts1',
    GamesPageAbTestSorts2 = 'GamesPageAbTestSorts2'
}

/**
 * 
 * @export
 * @interface RobloxGamesApiModelsRequestGamesSearchRequest
 */
export interface RobloxGamesApiModelsRequestGamesSearchRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    sortToken?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    gameFilter?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    timeFilter?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    genreFilter?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    exclusiveStartId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    sortOrder?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    gameSetTargetId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    keyword?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    startRows?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    maxRows?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    isKeywordSuggestionEnabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    contextCountryRegionId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    contextUniverseId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    pageId?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsRequestGamesSearchRequest
     */
    sortPosition?: number;
}
/**
 * A request for set user\'s vote for a game
 * @export
 * @interface RobloxGamesApiModelsRequestSetUserGameVoteRequest
 */
export interface RobloxGamesApiModelsRequestSetUserGameVoteRequest {
    /**
     * The vote to set
     * @type {boolean}
     * @memberof RobloxGamesApiModelsRequestSetUserGameVoteRequest
     */
    vote?: boolean;
}
/**
 * Response model for getting the game creator
 * @export
 * @interface RobloxGamesApiModelsResponseGameCreator
 */
export interface RobloxGamesApiModelsResponseGameCreator {
    /**
     * The game creator id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameCreator
     */
    id?: number;
    /**
     * The game creator name
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameCreator
     */
    name?: string;
    /**
     * The game creator type
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameCreator
     */
    type?: string;
}
/**
 * Response model for getting the game detail
 * @export
 * @interface RobloxGamesApiModelsResponseGameDetailResponse
 */
export interface RobloxGamesApiModelsResponseGameDetailResponse {
    /**
     * The game universe id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    id?: number;
    /**
     * The game root place id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    rootPlaceId?: number;
    /**
     * The game name
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    name?: string;
    /**
     * The game description
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    description?: string;
    /**
     * 
     * @type {RobloxGamesApiModelsResponseGameCreator}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    creator?: RobloxGamesApiModelsResponseGameCreator;
    /**
     * The game paid access price
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    price?: number;
    /**
     * Whether the game is experimental
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    isExperimental?: boolean;
    /**
     * The game allowed gear genres
     * @type {Array<string>}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    allowedGearGenres?: Array<string>;
    /**
     * The game allowed gear categoris
     * @type {Array<string>}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    allowedGearCategories?: Array<string>;
    /**
     * Current player count of the game
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    playing?: number;
    /**
     * The total visits to the game
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    visits?: number;
    /**
     * The game max players
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    maxPlayers?: number;
    /**
     * The game created time
     * @type {Date}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    created?: Date;
    /**
     * The game updated time
     * @type {Date}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    updated?: Date;
    /**
     * The setting of IsStudioAccessToApisAllowed of the universe
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    studioAccessToApisAllowed?: boolean;
    /**
     * Avatar type. Possible values are MorphToR6, MorphToR15, and PlayerChoice
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    universeAvatarType?: RobloxGamesApiModelsResponseGameDetailResponseUniverseAvatarTypeEnum;
    /**
     * The game genre display name
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameDetailResponse
     */
    genre?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGamesApiModelsResponseGameDetailResponseUniverseAvatarTypeEnum {
    MorphToR6 = 'MorphToR6',
    PlayerChoice = 'PlayerChoice',
    MorphToR15 = 'MorphToR15'
}

/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGameFavoritesCountResponse
 */
export interface RobloxGamesApiModelsResponseGameFavoritesCountResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameFavoritesCountResponse
     */
    favoritesCount?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGameFilter
 */
export interface RobloxGamesApiModelsResponseGameFilter {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameFilter
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameFilter
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameFilter
     */
    tokenExpiryInSeconds?: number;
}
/**
 * Response model for getting the game icon.
 * @export
 * @interface RobloxGamesApiModelsResponseGameIconResponse
 */
export interface RobloxGamesApiModelsResponseGameIconResponse {
    /**
     * The icon image id or null - if the default icon is used.
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameIconResponse
     */
    imageId?: number;
}
/**
 * Response model for getting the game media item
 * @export
 * @interface RobloxGamesApiModelsResponseGameMediaItem
 */
export interface RobloxGamesApiModelsResponseGameMediaItem {
    /**
     * The media item id.
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    id?: number;
    /**
     * The media item type id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    assetTypeId?: number;
    /**
     * The media item type, Image or YouTubeVideo
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    assetType?: string;
    /**
     * The media item image id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    imageId?: number;
    /**
     * The media item video hash
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    videoHash?: string;
    /**
     * The video title for video items.
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    videoTitle?: string;
    /**
     * The media item is approved or not
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameMediaItem
     */
    approved?: boolean;
}
/**
 * Response model for getting game passes
 * @export
 * @interface RobloxGamesApiModelsResponseGamePassResponse
 */
export interface RobloxGamesApiModelsResponseGamePassResponse {
    /**
     * The game pass id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamePassResponse
     */
    id?: number;
    /**
     * The game pass name
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGamePassResponse
     */
    name?: string;
    /**
     * The game pass product id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamePassResponse
     */
    productId?: number;
    /**
     * The game pass price
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamePassResponse
     */
    price?: number;
}
/**
 * Response model for getting the game product information
 * @export
 * @interface RobloxGamesApiModelsResponseGameProductResponse
 */
export interface RobloxGamesApiModelsResponseGameProductResponse {
    /**
     * The game universe id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameProductResponse
     */
    universeId?: number;
    /**
     * The game purchasability
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameProductResponse
     */
    isForSale?: boolean;
    /**
     * The game product id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameProductResponse
     */
    productId?: number;
    /**
     * The game price
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameProductResponse
     */
    price?: number;
    /**
     * The game seller id  User ID for users, Agent ID for groups
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameProductResponse
     */
    sellerId?: number;
}
/**
 * Game recommendations response model.
 * @export
 * @interface RobloxGamesApiModelsResponseGameRecommendationsResponse
 */
export interface RobloxGamesApiModelsResponseGameRecommendationsResponse {
    /**
     * The recommended games.
     * @type {Array<RobloxGamesApiModelsResponseGameResponseModel>}
     * @memberof RobloxGamesApiModelsResponseGameRecommendationsResponse
     */
    games?: Array<RobloxGamesApiModelsResponseGameResponseModel>;
    /**
     * The pagination key for next page query.  It will be null if there is no more data.
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameRecommendationsResponse
     */
    nextPaginationKey?: string;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGameResponseModel
 */
export interface RobloxGamesApiModelsResponseGameResponseModel {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    creatorId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    creatorName?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    creatorType?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    totalUpVotes?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    totalDownVotes?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    universeId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    placeId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    playerCount?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    imageToken?: string;
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseUser>}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    users?: Array<RobloxGamesApiModelsResponseUser>;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    isSponsored?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    nativeAdData?: string;
    /**
     * The game paid access price
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    price?: number;
    /**
     * Provide all necessary information which helps analytics for improvement, for example, the algorithm, dataset version, position, etc..
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameResponseModel
     */
    analyticsIdentifier?: string;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGameSort
 */
export interface RobloxGamesApiModelsResponseGameSort {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    displayName?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    gameSetTargetId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    timeOptionsAvailable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    genreOptionsAvailable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    numberOfRows?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    numberOfGames?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    isDefaultSort?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    contextUniverseId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    contextCountryRegionId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameSort
     */
    tokenExpiryInSeconds?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGameSortsResponse
 */
export interface RobloxGamesApiModelsResponseGameSortsResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameSort>}
     * @memberof RobloxGamesApiModelsResponseGameSortsResponse
     */
    sorts?: Array<RobloxGamesApiModelsResponseGameSort>;
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseTimeFilter>}
     * @memberof RobloxGamesApiModelsResponseGameSortsResponse
     */
    timeFilters?: Array<RobloxGamesApiModelsResponseTimeFilter>;
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGenreFilter>}
     * @memberof RobloxGamesApiModelsResponseGameSortsResponse
     */
    genreFilters?: Array<RobloxGamesApiModelsResponseGenreFilter>;
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameFilter>}
     * @memberof RobloxGamesApiModelsResponseGameSortsResponse
     */
    gameFilters?: Array<RobloxGamesApiModelsResponseGameFilter>;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGameSortsResponse
     */
    pageId?: string;
}
/**
 * Response model for getting the game vote
 * @export
 * @interface RobloxGamesApiModelsResponseGameVoteResponse
 */
export interface RobloxGamesApiModelsResponseGameVoteResponse {
    /**
     * The game universe id
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameVoteResponse
     */
    id?: number;
    /**
     * The game up votes
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameVoteResponse
     */
    upVotes?: number;
    /**
     * The game down votes
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGameVoteResponse
     */
    downVotes?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGamesSearchResponse
 */
export interface RobloxGamesApiModelsResponseGamesSearchResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameResponseModel>}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    games?: Array<RobloxGamesApiModelsResponseGameResponseModel>;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    suggestedKeyword?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    correctedKeyword?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    filteredKeyword?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    hasMoreRows?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    nextPageExclusiveStartId?: number;
    /**
     * Position of the sort on the page.
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    sortPosition?: number;
    /**
     * PageId tying all sorts on the page.
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    pageId?: string;
    /**
     * Pagenumber of fetch for the sort or search.
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGamesSearchResponse
     */
    page?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseGenreFilter
 */
export interface RobloxGamesApiModelsResponseGenreFilter {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGenreFilter
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseGenreFilter
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseGenreFilter
     */
    tokenExpiryInSeconds?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponsePlaceDetails
 */
export interface RobloxGamesApiModelsResponsePlaceDetails {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    placeId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    builder?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    builderId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    isPlayable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    reasonProhibited?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    universeId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    universeRootPlaceId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlaceDetails
     */
    imageToken?: string;
}
/**
 * Response model for getting a universe\'s playability status for a user
 * @export
 * @interface RobloxGamesApiModelsResponsePlayabilityStatusResponse
 */
export interface RobloxGamesApiModelsResponsePlayabilityStatusResponse {
    /**
     * The actual playability status of the universe including the reason if unplayable
     * @type {string}
     * @memberof RobloxGamesApiModelsResponsePlayabilityStatusResponse
     */
    playabilityStatus?: RobloxGamesApiModelsResponsePlayabilityStatusResponsePlayabilityStatusEnum;
    /**
     * Whether or not the universe is playable for the user
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponsePlayabilityStatusResponse
     */
    isPlayable?: boolean;
    /**
     * The universeId of the requested universe to help with batching requests
     * @type {number}
     * @memberof RobloxGamesApiModelsResponsePlayabilityStatusResponse
     */
    universeId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGamesApiModelsResponsePlayabilityStatusResponsePlayabilityStatusEnum {
    UnplayableOtherReason = 'UnplayableOtherReason',
    Playable = 'Playable',
    GuestProhibited = 'GuestProhibited',
    GameUnapproved = 'GameUnapproved',
    IncorrectConfiguration = 'IncorrectConfiguration',
    UniverseRootPlaceIsPrivate = 'UniverseRootPlaceIsPrivate',
    InsufficientPermissionFriendsOnly = 'InsufficientPermissionFriendsOnly',
    InsufficientPermissionGroupOnly = 'InsufficientPermissionGroupOnly',
    DeviceRestricted = 'DeviceRestricted',
    UnderReview = 'UnderReview',
    PurchaseRequired = 'PurchaseRequired',
    AccountRestricted = 'AccountRestricted',
    TemporarilyUnavailable = 'TemporarilyUnavailable'
}

/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseThumbnail
 */
export interface RobloxGamesApiModelsResponseThumbnail {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    _final?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    cdnUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    retryToken?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    universeId?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseThumbnail
     */
    placeId?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseTimeFilter
 */
export interface RobloxGamesApiModelsResponseTimeFilter {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseTimeFilter
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseTimeFilter
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseTimeFilter
     */
    tokenExpiryInSeconds?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiModelsResponseUser
 */
export interface RobloxGamesApiModelsResponseUser {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiModelsResponseUser
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseUser
     */
    gameId?: string;
}
/**
 * Response model for getting the user vote status for a game
 * @export
 * @interface RobloxGamesApiModelsResponseUserGameVoteResponse
 */
export interface RobloxGamesApiModelsResponseUserGameVoteResponse {
    /**
     * Whether the user can vote the game
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseUserGameVoteResponse
     */
    canVote?: boolean;
    /**
     * The user\'s vote status for the game
     * @type {boolean}
     * @memberof RobloxGamesApiModelsResponseUserGameVoteResponse
     */
    userVote?: boolean;
    /**
     * The reason why the user can\'t vote
     * @type {string}
     * @memberof RobloxGamesApiModelsResponseUserGameVoteResponse
     */
    reasonForNotVoteable?: string;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiPlaceResponse
 */
export interface RobloxGamesApiPlaceResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiPlaceResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiPlaceResponse
     */
    name?: string;
}
/**
 * Model for setting metadata
 * @export
 * @interface RobloxGamesApiReportPlaceMetadataRequest
 */
export interface RobloxGamesApiReportPlaceMetadataRequest {
    /**
     * How many points to award to the user.
     * @type {boolean}
     * @memberof RobloxGamesApiReportPlaceMetadataRequest
     */
    filteringEnabled?: boolean;
}
/**
 * A social link for a game
 * @export
 * @interface RobloxGamesApiSocialLinkResponse
 */
export interface RobloxGamesApiSocialLinkResponse {
    /**
     * The id of the social link
     * @type {number}
     * @memberof RobloxGamesApiSocialLinkResponse
     */
    id?: number;
    /**
     * The title given to the social link
     * @type {string}
     * @memberof RobloxGamesApiSocialLinkResponse
     */
    title?: string;
    /**
     * The url of the social link
     * @type {string}
     * @memberof RobloxGamesApiSocialLinkResponse
     */
    url?: string;
    /**
     * The type (Facebook/Twitter) of the social link.
     * @type {string}
     * @memberof RobloxGamesApiSocialLinkResponse
     */
    type?: RobloxGamesApiSocialLinkResponseTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxGamesApiSocialLinkResponseTypeEnum {
    Facebook = 'Facebook',
    Twitter = 'Twitter',
    YouTube = 'YouTube',
    Twitch = 'Twitch',
    GooglePlus = 'GooglePlus',
    Discord = 'Discord',
    RobloxGroup = 'RobloxGroup'
}

/**
 * 
 * @export
 * @interface RobloxGamesApiUserResponse
 */
export interface RobloxGamesApiUserResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiUserResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiUserResponse
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerPermissionsResponse
 */
export interface RobloxGamesApiVipServerPermissionsResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerPermissionsResponse
     */
    clanAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiVipServerPermissionsResponse
     */
    enemyClanId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerPermissionsResponse
     */
    friendsAllowed?: boolean;
    /**
     * 
     * @type {Array<RobloxGamesApiUserResponse>}
     * @memberof RobloxGamesApiVipServerPermissionsResponse
     */
    users?: Array<RobloxGamesApiUserResponse>;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerResponse
 */
export interface RobloxGamesApiVipServerResponse {
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiVipServerResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiVipServerResponse
     */
    name?: string;
    /**
     * 
     * @type {RobloxGamesApiGameResponse}
     * @memberof RobloxGamesApiVipServerResponse
     */
    game?: RobloxGamesApiGameResponse;
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiVipServerResponse
     */
    joinCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerResponse
     */
    active?: boolean;
    /**
     * 
     * @type {RobloxGamesApiVipServerSubscriptionResponse}
     * @memberof RobloxGamesApiVipServerResponse
     */
    subscription?: RobloxGamesApiVipServerSubscriptionResponse;
    /**
     * 
     * @type {RobloxGamesApiVipServerPermissionsResponse}
     * @memberof RobloxGamesApiVipServerResponse
     */
    permissions?: RobloxGamesApiVipServerPermissionsResponse;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerSubscriptionResponse
 */
export interface RobloxGamesApiVipServerSubscriptionResponse {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerSubscriptionResponse
     */
    active?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerSubscriptionResponse
     */
    expired?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof RobloxGamesApiVipServerSubscriptionResponse
     */
    expirationDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiVipServerSubscriptionResponse
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerUpdatePermissionsRequest
 */
export interface RobloxGamesApiVipServerUpdatePermissionsRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerUpdatePermissionsRequest
     */
    clanAllowed?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiVipServerUpdatePermissionsRequest
     */
    enemyClanId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerUpdatePermissionsRequest
     */
    friendsAllowed?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof RobloxGamesApiVipServerUpdatePermissionsRequest
     */
    usersToAdd?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof RobloxGamesApiVipServerUpdatePermissionsRequest
     */
    usersToRemove?: Array<number>;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerUpdateRequest
 */
export interface RobloxGamesApiVipServerUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RobloxGamesApiVipServerUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerUpdateRequest
     */
    newJoinCode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerUpdateRequest
     */
    active?: boolean;
}
/**
 * 
 * @export
 * @interface RobloxGamesApiVipServerUpdateSubscriptionRequest
 */
export interface RobloxGamesApiVipServerUpdateSubscriptionRequest {
    /**
     * 
     * @type {boolean}
     * @memberof RobloxGamesApiVipServerUpdateSubscriptionRequest
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RobloxGamesApiVipServerUpdateSubscriptionRequest
     */
    price?: number;
}
/**
 * 
 * @export
 * @interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
 */
export interface RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64 {
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    sortOrder?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum;
    /**
     * 
     * @type {string}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    pagingDirection?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum;
    /**
     * 
     * @type {number}
     * @memberof RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64
     */
    count?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64SortOrderEnum {
    Asc = 'Asc',
    Desc = 'Desc'
}
/**
    * @export
    * @enum {string}
    */
export enum RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64PagingDirectionEnum {
    Forward = 'Forward',
    Backward = 'Backward'
}

/**
 * 
 * @export
 * @interface RobloxWebResponsesGamesGameServerResponse
 */
export interface RobloxWebResponsesGamesGameServerResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    maxPlayers?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    playing?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    fps?: number;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    ping?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    vipServerId?: number;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebResponsesGamesGameServerResponse
     */
    accessCode?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIExclusiveStartRequestSystemInt64
 */
export interface RobloxWebWebAPIExclusiveStartRequestSystemInt64 {
    /**
     * 
     * @type {RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
     */
    exclusiveStartKeyInfo?: RobloxPlatformCoreExclusiveStartPagingIExclusiveStartKeyInfoSystemInt64;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIExclusiveStartRequestSystemInt64
     */
    cursorRecipe?: string;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameDetailResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameDetailResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameDetailResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameDetailResponse
     */
    data?: Array<RobloxGamesApiModelsResponseGameDetailResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameMediaItem
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameMediaItem {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameMediaItem>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameMediaItem
     */
    data?: Array<RobloxGamesApiModelsResponseGameMediaItem>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameProductResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameProductResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameProductResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameProductResponse
     */
    data?: Array<RobloxGamesApiModelsResponseGameProductResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameVoteResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameVoteResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGameVoteResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameVoteResponse
     */
    data?: Array<RobloxGamesApiModelsResponseGameVoteResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiSocialLinkResponse
 */
export interface RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiSocialLinkResponse {
    /**
     * 
     * @type {Array<RobloxGamesApiSocialLinkResponse>}
     * @memberof RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiSocialLinkResponse
     */
    data?: Array<RobloxGamesApiSocialLinkResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse
 */
export interface RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<RobloxGamesApiModelsResponseGamePassResponse>}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse
     */
    data?: Array<RobloxGamesApiModelsResponseGamePassResponse>;
}
/**
 * 
 * @export
 * @interface RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse
 */
export interface RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse {
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse
     */
    previousPageCursor?: string;
    /**
     * 
     * @type {string}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse
     */
    nextPageCursor?: string;
    /**
     * 
     * @type {Array<RobloxWebResponsesGamesGameServerResponse>}
     * @memberof RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse
     */
    data?: Array<RobloxWebResponsesGamesGameServerResponse>;
}

/**
 * FavoritesApi - axios parameter creator
 * @export
 */
export const FavoritesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the favorites count of the a specific game
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesCountGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdFavoritesCountGet.');
            }
            const localVarPath = `/v1/games/{universeId}/favorites/count`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns if a game was marked as favorite for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdFavoritesGet.');
            }
            const localVarPath = `/v1/games/{universeId}/favorites`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Favors (or unfavors) a game for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {RobloxGamesApiModelsRequestGameFavoritesRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesPost(universeId: number, request: RobloxGamesApiModelsRequestGameFavoritesRequest, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdFavoritesPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GamesUniverseIdFavoritesPost.');
            }
            const localVarPath = `/v1/games/{universeId}/favorites`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiModelsRequestGameFavoritesRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FavoritesApi - functional programming interface
 * @export
 */
export const FavoritesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the favorites count of the a specific game
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesCountGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameFavoritesCountResponse> {
            const localVarAxiosArgs = FavoritesApiAxiosParamCreator(configuration).v1GamesUniverseIdFavoritesCountGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Returns if a game was marked as favorite for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsRequestGameFavoriteResponse> {
            const localVarAxiosArgs = FavoritesApiAxiosParamCreator(configuration).v1GamesUniverseIdFavoritesGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Favors (or unfavors) a game for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {RobloxGamesApiModelsRequestGameFavoritesRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesPost(universeId: number, request: RobloxGamesApiModelsRequestGameFavoritesRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = FavoritesApiAxiosParamCreator(configuration).v1GamesUniverseIdFavoritesPost(universeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * FavoritesApi - factory interface
 * @export
 */
export const FavoritesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the favorites count of the a specific game
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesCountGet(universeId: number, options?: any) {
            return FavoritesApiFp(configuration).v1GamesUniverseIdFavoritesCountGet(universeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Returns if a game was marked as favorite for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesGet(universeId: number, options?: any) {
            return FavoritesApiFp(configuration).v1GamesUniverseIdFavoritesGet(universeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Favors (or unfavors) a game for the authenticated user
         * @param {number} universeId The Id of the universe.
         * @param {RobloxGamesApiModelsRequestGameFavoritesRequest} request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdFavoritesPost(universeId: number, request: RobloxGamesApiModelsRequestGameFavoritesRequest, options?: any) {
            return FavoritesApiFp(configuration).v1GamesUniverseIdFavoritesPost(universeId, request, options)(axios, basePath);
        },
    };
};

/**
 * FavoritesApi - interface
 * @export
 * @interface FavoritesApi
 */
export interface FavoritesApiInterface {
    /**
     * 
     * @summary Get the favorites count of the a specific game
     * @param {number} universeId The Id of the universe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApiInterface
     */
    v1GamesUniverseIdFavoritesCountGet(universeId: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameFavoritesCountResponse>;

    /**
     * 
     * @summary Returns if a game was marked as favorite for the authenticated user
     * @param {number} universeId The Id of the universe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApiInterface
     */
    v1GamesUniverseIdFavoritesGet(universeId: number, options?: any): AxiosPromise<RobloxGamesApiModelsRequestGameFavoriteResponse>;

    /**
     * 
     * @summary Favors (or unfavors) a game for the authenticated user
     * @param {number} universeId The Id of the universe.
     * @param {RobloxGamesApiModelsRequestGameFavoritesRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApiInterface
     */
    v1GamesUniverseIdFavoritesPost(universeId: number, request: RobloxGamesApiModelsRequestGameFavoritesRequest, options?: any): AxiosPromise<object>;

}

/**
 * FavoritesApi - object-oriented interface
 * @export
 * @class FavoritesApi
 * @extends {BaseAPI}
 */
export class FavoritesApi extends BaseAPI implements FavoritesApiInterface {
    /**
     * 
     * @summary Get the favorites count of the a specific game
     * @param {number} universeId The Id of the universe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    public v1GamesUniverseIdFavoritesCountGet(universeId: number, options?: any) {
        return FavoritesApiFp(this.configuration).v1GamesUniverseIdFavoritesCountGet(universeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Returns if a game was marked as favorite for the authenticated user
     * @param {number} universeId The Id of the universe.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    public v1GamesUniverseIdFavoritesGet(universeId: number, options?: any) {
        return FavoritesApiFp(this.configuration).v1GamesUniverseIdFavoritesGet(universeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Favors (or unfavors) a game for the authenticated user
     * @param {number} universeId The Id of the universe.
     * @param {RobloxGamesApiModelsRequestGameFavoritesRequest} request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FavoritesApi
     */
    public v1GamesUniverseIdFavoritesPost(universeId: number, request: RobloxGamesApiModelsRequestGameFavoritesRequest, options?: any) {
        return FavoritesApiFp(this.configuration).v1GamesUniverseIdFavoritesPost(universeId, request, options)(this.axios, this.basePath);
    }

}


/**
 * GamePassesApi - axios parameter creator
 * @export
 */
export const GamePassesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the game\'s game passes
         * @param {number} universeId The id of the universe.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdGamePassesGet(universeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdGamePassesGet.');
            }
            const localVarPath = `/v1/games/{universeId}/game-passes`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamePassesApi - functional programming interface
 * @export
 */
export const GamePassesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get the game\'s game passes
         * @param {number} universeId The id of the universe.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdGamePassesGet(universeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse> {
            const localVarAxiosArgs = GamePassesApiAxiosParamCreator(configuration).v1GamesUniverseIdGamePassesGet(universeId, sortOrder, limit, cursor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GamePassesApi - factory interface
 * @export
 */
export const GamePassesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get the game\'s game passes
         * @param {number} universeId The id of the universe.
         * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdGamePassesGet(universeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
            return GamePassesApiFp(configuration).v1GamesUniverseIdGamePassesGet(universeId, sortOrder, limit, cursor, options)(axios, basePath);
        },
    };
};

/**
 * GamePassesApi - interface
 * @export
 * @interface GamePassesApi
 */
export interface GamePassesApiInterface {
    /**
     * 
     * @summary Get the game\'s game passes
     * @param {number} universeId The id of the universe.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassesApiInterface
     */
    v1GamesUniverseIdGamePassesGet(universeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxGamesApiModelsResponseGamePassResponse>;

}

/**
 * GamePassesApi - object-oriented interface
 * @export
 * @class GamePassesApi
 * @extends {BaseAPI}
 */
export class GamePassesApi extends BaseAPI implements GamePassesApiInterface {
    /**
     * 
     * @summary Get the game\'s game passes
     * @param {number} universeId The id of the universe.
     * @param {'Asc' | 'Desc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamePassesApi
     */
    public v1GamesUniverseIdGamePassesGet(universeId: number, sortOrder?: 'Asc' | 'Desc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return GamePassesApiFp(this.configuration).v1GamesUniverseIdGamePassesGet(universeId, sortOrder, limit, cursor, options)(this.axios, this.basePath);
    }

}


/**
 * GamesApi - axios parameter creator
 * @export
 */
export const GamesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a single game thumbnail
         * @param {string} imageToken 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailGet(imageToken: string, height?: number, width?: number, options: any = {}): RequestArgs {
            // verify required parameter 'imageToken' is not null or undefined
            if (imageToken === null || imageToken === undefined) {
                throw new RequiredError('imageToken','Required parameter imageToken was null or undefined when calling v1GamesGameThumbnailGet.');
            }
            const localVarPath = `/v1/games/game-thumbnail`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (imageToken !== undefined) {
                localVarQueryParameter['imageToken'] = imageToken;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of game thumbnails
         * @param {Array<string>} imageTokens 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailsGet(imageTokens: Array<string>, height?: number, width?: number, options: any = {}): RequestArgs {
            // verify required parameter 'imageTokens' is not null or undefined
            if (imageTokens === null || imageTokens === undefined) {
                throw new RequiredError('imageTokens','Required parameter imageTokens was null or undefined when calling v1GamesGameThumbnailsGet.');
            }
            const localVarPath = `/v1/games/game-thumbnails`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (imageTokens) {
                localVarQueryParameter['imageTokens'] = imageTokens;
            }

            if (height !== undefined) {
                localVarQueryParameter['height'] = height;
            }

            if (width !== undefined) {
                localVarQueryParameter['width'] = width;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of games\' product info, used to purchase a game
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGamesProductInfoGet(universeIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'universeIds' is not null or undefined
            if (universeIds === null || universeIds === undefined) {
                throw new RequiredError('universeIds','Required parameter universeIds was null or undefined when calling v1GamesGamesProductInfoGet.');
            }
            const localVarPath = `/v1/games/games-product-info`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of games\' detail
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGet(universeIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'universeIds' is not null or undefined
            if (universeIds === null || universeIds === undefined) {
                throw new RequiredError('universeIds','Required parameter universeIds was null or undefined when calling v1GamesGet.');
            }
            const localVarPath = `/v1/games`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of games
         * @param {string} [modelSortToken] 
         * @param {string} [modelGameFilter] 
         * @param {string} [modelTimeFilter] 
         * @param {string} [modelGenreFilter] 
         * @param {number} [modelExclusiveStartId] 
         * @param {number} [modelSortOrder] 
         * @param {number} [modelGameSetTargetId] 
         * @param {string} [modelKeyword] 
         * @param {number} [modelStartRows] 
         * @param {number} [modelMaxRows] 
         * @param {boolean} [modelIsKeywordSuggestionEnabled] 
         * @param {number} [modelContextCountryRegionId] 
         * @param {number} [modelContextUniverseId] 
         * @param {string} [modelPageId] 
         * @param {number} [modelSortPosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesListGet(modelSortToken?: string, modelGameFilter?: string, modelTimeFilter?: string, modelGenreFilter?: string, modelExclusiveStartId?: number, modelSortOrder?: number, modelGameSetTargetId?: number, modelKeyword?: string, modelStartRows?: number, modelMaxRows?: number, modelIsKeywordSuggestionEnabled?: boolean, modelContextCountryRegionId?: number, modelContextUniverseId?: number, modelPageId?: string, modelSortPosition?: number, options: any = {}): RequestArgs {
            const localVarPath = `/v1/games/list`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelSortToken !== undefined) {
                localVarQueryParameter['model.sortToken'] = modelSortToken;
            }

            if (modelGameFilter !== undefined) {
                localVarQueryParameter['model.gameFilter'] = modelGameFilter;
            }

            if (modelTimeFilter !== undefined) {
                localVarQueryParameter['model.timeFilter'] = modelTimeFilter;
            }

            if (modelGenreFilter !== undefined) {
                localVarQueryParameter['model.genreFilter'] = modelGenreFilter;
            }

            if (modelExclusiveStartId !== undefined) {
                localVarQueryParameter['model.exclusiveStartId'] = modelExclusiveStartId;
            }

            if (modelSortOrder !== undefined) {
                localVarQueryParameter['model.sortOrder'] = modelSortOrder;
            }

            if (modelGameSetTargetId !== undefined) {
                localVarQueryParameter['model.gameSetTargetId'] = modelGameSetTargetId;
            }

            if (modelKeyword !== undefined) {
                localVarQueryParameter['model.keyword'] = modelKeyword;
            }

            if (modelStartRows !== undefined) {
                localVarQueryParameter['model.startRows'] = modelStartRows;
            }

            if (modelMaxRows !== undefined) {
                localVarQueryParameter['model.maxRows'] = modelMaxRows;
            }

            if (modelIsKeywordSuggestionEnabled !== undefined) {
                localVarQueryParameter['model.isKeywordSuggestionEnabled'] = modelIsKeywordSuggestionEnabled;
            }

            if (modelContextCountryRegionId !== undefined) {
                localVarQueryParameter['model.contextCountryRegionId'] = modelContextCountryRegionId;
            }

            if (modelContextUniverseId !== undefined) {
                localVarQueryParameter['model.contextUniverseId'] = modelContextUniverseId;
            }

            if (modelPageId !== undefined) {
                localVarQueryParameter['model.pageId'] = modelPageId;
            }

            if (modelSortPosition !== undefined) {
                localVarQueryParameter['model.sortPosition'] = modelSortPosition;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get place details
         * @param {Array<number>} placeIds List of placeId to uniquely Identify a place
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlaceDetailsGet(placeIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'placeIds' is not null or undefined
            if (placeIds === null || placeIds === undefined) {
                throw new RequiredError('placeIds','Required parameter placeIds was null or undefined when calling v1GamesMultigetPlaceDetailsGet.');
            }
            const localVarPath = `/v1/games/multiget-place-details`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (placeIds) {
                localVarQueryParameter['placeIds'] = placeIds;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of universe playability statuses for the authenticated user
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlayabilityStatusGet(universeIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'universeIds' is not null or undefined
            if (universeIds === null || universeIds === undefined) {
                throw new RequiredError('universeIds','Required parameter universeIds was null or undefined when calling v1GamesMultigetPlayabilityStatusGet.');
            }
            const localVarPath = `/v1/games/multiget-playability-status`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the game server list
         * @param {number} placeId The Id of the place we are geting the server list for.
         * @param {'Public' | 'Friend' | 'VIP'} serverType The type of the server we geting the server list for.
         * @param {'Asc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlaceIdServersServerTypeGet(placeId: number, serverType: 'Public' | 'Friend' | 'VIP', sortOrder?: 'Asc', limit?: 10 | 25 | 50 | 100, cursor?: string, options: any = {}): RequestArgs {
            // verify required parameter 'placeId' is not null or undefined
            if (placeId === null || placeId === undefined) {
                throw new RequiredError('placeId','Required parameter placeId was null or undefined when calling v1GamesPlaceIdServersServerTypeGet.');
            }
            // verify required parameter 'serverType' is not null or undefined
            if (serverType === null || serverType === undefined) {
                throw new RequiredError('serverType','Required parameter serverType was null or undefined when calling v1GamesPlaceIdServersServerTypeGet.');
            }
            const localVarPath = `/v1/games/{placeId}/servers/{serverType}`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)))
                .replace(`{${"serverType"}}`, encodeURIComponent(String(serverType)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (cursor !== undefined) {
                localVarQueryParameter['cursor'] = cursor;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Used by the GameServer to set a place\'s metadata, including whether it is filtering enabled.
         * @param {number} placeId The Id of the place we are setting the metadata for.
         * @param {RobloxGamesApiReportPlaceMetadataRequest} request An {Roblox.Games.Api.ReportPlaceMetadataRequest}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlacesPlaceIdMetadataPost(placeId: number, request: RobloxGamesApiReportPlaceMetadataRequest, options: any = {}): RequestArgs {
            // verify required parameter 'placeId' is not null or undefined
            if (placeId === null || placeId === undefined) {
                throw new RequiredError('placeId','Required parameter placeId was null or undefined when calling v1GamesPlacesPlaceIdMetadataPost.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1GamesPlacesPlaceIdMetadataPost.');
            }
            const localVarPath = `/v1/games/places/{placeId}/metadata`
                .replace(`{${"placeId"}}`, encodeURIComponent(String(placeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiReportPlaceMetadataRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get games recommendations
         * @param {string} algorithmName The algorithm name of recommendations
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName: string, modelPaginationKey?: string, modelMaxRows?: number, options: any = {}): RequestArgs {
            // verify required parameter 'algorithmName' is not null or undefined
            if (algorithmName === null || algorithmName === undefined) {
                throw new RequiredError('algorithmName','Required parameter algorithmName was null or undefined when calling v1GamesRecommendationsAlgorithmAlgorithmNameGet.');
            }
            const localVarPath = `/v1/games/recommendations/algorithm/{algorithmName}`
                .replace(`{${"algorithmName"}}`, encodeURIComponent(String(algorithmName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelPaginationKey !== undefined) {
                localVarQueryParameter['model.paginationKey'] = modelPaginationKey;
            }

            if (modelMaxRows !== undefined) {
                localVarQueryParameter['model.maxRows'] = modelMaxRows;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get games recommendations based on a given universe
         * @param {number} universeId The universe to base recommendations on
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsGameUniverseIdGet(universeId: number, modelPaginationKey?: string, modelMaxRows?: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesRecommendationsGameUniverseIdGet.');
            }
            const localVarPath = `/v1/games/recommendations/game/{universeId}`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelPaginationKey !== undefined) {
                localVarQueryParameter['model.paginationKey'] = modelPaginationKey;
            }

            if (modelMaxRows !== undefined) {
                localVarQueryParameter['model.maxRows'] = modelMaxRows;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets an ordered list of all sorts
         * @param {'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2'} [modelGameSortsContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesSortsGet(modelGameSortsContext?: 'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2', options: any = {}): RequestArgs {
            const localVarPath = `/v1/games/sorts`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (modelGameSortsContext !== undefined) {
                localVarQueryParameter['model.gameSortsContext'] = modelGameSortsContext;
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the game icon
         * @param {number} universeId The id of the universe to get the icon for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdIconGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdIconGet.');
            }
            const localVarPath = `/v1/games/{universeId}/icon`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdMediaGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdMediaGet.');
            }
            const localVarPath = `/v1/games/{universeId}/media`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GamesApi - functional programming interface
 * @export
 */
export const GamesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a single game thumbnail
         * @param {string} imageToken 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailGet(imageToken: string, height?: number, width?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesGameThumbnailGet(imageToken, height, width, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of game thumbnails
         * @param {Array<string>} imageTokens 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailsGet(imageTokens: Array<string>, height?: number, width?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RobloxGamesApiModelsResponseThumbnail>> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesGameThumbnailsGet(imageTokens, height, width, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of games\' product info, used to purchase a game
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGamesProductInfoGet(universeIds: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameProductResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesGamesProductInfoGet(universeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of games\' detail
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGet(universeIds: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameDetailResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesGet(universeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of games
         * @param {string} [modelSortToken] 
         * @param {string} [modelGameFilter] 
         * @param {string} [modelTimeFilter] 
         * @param {string} [modelGenreFilter] 
         * @param {number} [modelExclusiveStartId] 
         * @param {number} [modelSortOrder] 
         * @param {number} [modelGameSetTargetId] 
         * @param {string} [modelKeyword] 
         * @param {number} [modelStartRows] 
         * @param {number} [modelMaxRows] 
         * @param {boolean} [modelIsKeywordSuggestionEnabled] 
         * @param {number} [modelContextCountryRegionId] 
         * @param {number} [modelContextUniverseId] 
         * @param {string} [modelPageId] 
         * @param {number} [modelSortPosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesListGet(modelSortToken?: string, modelGameFilter?: string, modelTimeFilter?: string, modelGenreFilter?: string, modelExclusiveStartId?: number, modelSortOrder?: number, modelGameSetTargetId?: number, modelKeyword?: string, modelStartRows?: number, modelMaxRows?: number, modelIsKeywordSuggestionEnabled?: boolean, modelContextCountryRegionId?: number, modelContextUniverseId?: number, modelPageId?: string, modelSortPosition?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGamesSearchResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesListGet(modelSortToken, modelGameFilter, modelTimeFilter, modelGenreFilter, modelExclusiveStartId, modelSortOrder, modelGameSetTargetId, modelKeyword, modelStartRows, modelMaxRows, modelIsKeywordSuggestionEnabled, modelContextCountryRegionId, modelContextUniverseId, modelPageId, modelSortPosition, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get place details
         * @param {Array<number>} placeIds List of placeId to uniquely Identify a place
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlaceDetailsGet(placeIds: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RobloxGamesApiModelsResponsePlaceDetails>> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesMultigetPlaceDetailsGet(placeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of universe playability statuses for the authenticated user
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlayabilityStatusGet(universeIds: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RobloxGamesApiModelsResponsePlayabilityStatusResponse>> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesMultigetPlayabilityStatusGet(universeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the game server list
         * @param {number} placeId The Id of the place we are geting the server list for.
         * @param {'Public' | 'Friend' | 'VIP'} serverType The type of the server we geting the server list for.
         * @param {'Asc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlaceIdServersServerTypeGet(placeId: number, serverType: 'Public' | 'Friend' | 'VIP', sortOrder?: 'Asc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesPlaceIdServersServerTypeGet(placeId, serverType, sortOrder, limit, cursor, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Used by the GameServer to set a place\'s metadata, including whether it is filtering enabled.
         * @param {number} placeId The Id of the place we are setting the metadata for.
         * @param {RobloxGamesApiReportPlaceMetadataRequest} request An {Roblox.Games.Api.ReportPlaceMetadataRequest}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlacesPlaceIdMetadataPost(placeId: number, request: RobloxGamesApiReportPlaceMetadataRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesPlacesPlaceIdMetadataPost(placeId, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get games recommendations
         * @param {string} algorithmName The algorithm name of recommendations
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName: string, modelPaginationKey?: string, modelMaxRows?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameRecommendationsResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName, modelPaginationKey, modelMaxRows, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get games recommendations based on a given universe
         * @param {number} universeId The universe to base recommendations on
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsGameUniverseIdGet(universeId: number, modelPaginationKey?: string, modelMaxRows?: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameRecommendationsResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesRecommendationsGameUniverseIdGet(universeId, modelPaginationKey, modelMaxRows, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets an ordered list of all sorts
         * @param {'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2'} [modelGameSortsContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesSortsGet(modelGameSortsContext?: 'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2', options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameSortsResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesSortsGet(modelGameSortsContext, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the game icon
         * @param {number} universeId The id of the universe to get the icon for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdIconGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameIconResponse> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesUniverseIdIconGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdMediaGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameMediaItem> {
            const localVarAxiosArgs = GamesApiAxiosParamCreator(configuration).v1GamesUniverseIdMediaGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * GamesApi - factory interface
 * @export
 */
export const GamesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get a single game thumbnail
         * @param {string} imageToken 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailGet(imageToken: string, height?: number, width?: number, options?: any) {
            return GamesApiFp(configuration).v1GamesGameThumbnailGet(imageToken, height, width, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of game thumbnails
         * @param {Array<string>} imageTokens 
         * @param {number} [height] 
         * @param {number} [width] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGameThumbnailsGet(imageTokens: Array<string>, height?: number, width?: number, options?: any) {
            return GamesApiFp(configuration).v1GamesGameThumbnailsGet(imageTokens, height, width, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of games\' product info, used to purchase a game
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGamesProductInfoGet(universeIds: Array<number>, options?: any) {
            return GamesApiFp(configuration).v1GamesGamesProductInfoGet(universeIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of games\' detail
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesGet(universeIds: Array<number>, options?: any) {
            return GamesApiFp(configuration).v1GamesGet(universeIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of games
         * @param {string} [modelSortToken] 
         * @param {string} [modelGameFilter] 
         * @param {string} [modelTimeFilter] 
         * @param {string} [modelGenreFilter] 
         * @param {number} [modelExclusiveStartId] 
         * @param {number} [modelSortOrder] 
         * @param {number} [modelGameSetTargetId] 
         * @param {string} [modelKeyword] 
         * @param {number} [modelStartRows] 
         * @param {number} [modelMaxRows] 
         * @param {boolean} [modelIsKeywordSuggestionEnabled] 
         * @param {number} [modelContextCountryRegionId] 
         * @param {number} [modelContextUniverseId] 
         * @param {string} [modelPageId] 
         * @param {number} [modelSortPosition] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesListGet(modelSortToken?: string, modelGameFilter?: string, modelTimeFilter?: string, modelGenreFilter?: string, modelExclusiveStartId?: number, modelSortOrder?: number, modelGameSetTargetId?: number, modelKeyword?: string, modelStartRows?: number, modelMaxRows?: number, modelIsKeywordSuggestionEnabled?: boolean, modelContextCountryRegionId?: number, modelContextUniverseId?: number, modelPageId?: string, modelSortPosition?: number, options?: any) {
            return GamesApiFp(configuration).v1GamesListGet(modelSortToken, modelGameFilter, modelTimeFilter, modelGenreFilter, modelExclusiveStartId, modelSortOrder, modelGameSetTargetId, modelKeyword, modelStartRows, modelMaxRows, modelIsKeywordSuggestionEnabled, modelContextCountryRegionId, modelContextUniverseId, modelPageId, modelSortPosition, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get place details
         * @param {Array<number>} placeIds List of placeId to uniquely Identify a place
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlaceDetailsGet(placeIds: Array<number>, options?: any) {
            return GamesApiFp(configuration).v1GamesMultigetPlaceDetailsGet(placeIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of universe playability statuses for the authenticated user
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesMultigetPlayabilityStatusGet(universeIds: Array<number>, options?: any) {
            return GamesApiFp(configuration).v1GamesMultigetPlayabilityStatusGet(universeIds, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the game server list
         * @param {number} placeId The Id of the place we are geting the server list for.
         * @param {'Public' | 'Friend' | 'VIP'} serverType The type of the server we geting the server list for.
         * @param {'Asc'} [sortOrder] The order the results are sorted in.
         * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
         * @param {string} [cursor] The paging cursor for the previous or next page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlaceIdServersServerTypeGet(placeId: number, serverType: 'Public' | 'Friend' | 'VIP', sortOrder?: 'Asc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
            return GamesApiFp(configuration).v1GamesPlaceIdServersServerTypeGet(placeId, serverType, sortOrder, limit, cursor, options)(axios, basePath);
        },
        /**
         * 
         * @summary Used by the GameServer to set a place\'s metadata, including whether it is filtering enabled.
         * @param {number} placeId The Id of the place we are setting the metadata for.
         * @param {RobloxGamesApiReportPlaceMetadataRequest} request An {Roblox.Games.Api.ReportPlaceMetadataRequest}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesPlacesPlaceIdMetadataPost(placeId: number, request: RobloxGamesApiReportPlaceMetadataRequest, options?: any) {
            return GamesApiFp(configuration).v1GamesPlacesPlaceIdMetadataPost(placeId, request, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get games recommendations
         * @param {string} algorithmName The algorithm name of recommendations
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName: string, modelPaginationKey?: string, modelMaxRows?: number, options?: any) {
            return GamesApiFp(configuration).v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName, modelPaginationKey, modelMaxRows, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get games recommendations based on a given universe
         * @param {number} universeId The universe to base recommendations on
         * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
         * @param {number} [modelMaxRows] The requested number of rows.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesRecommendationsGameUniverseIdGet(universeId: number, modelPaginationKey?: string, modelMaxRows?: number, options?: any) {
            return GamesApiFp(configuration).v1GamesRecommendationsGameUniverseIdGet(universeId, modelPaginationKey, modelMaxRows, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets an ordered list of all sorts
         * @param {'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2'} [modelGameSortsContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesSortsGet(modelGameSortsContext?: 'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2', options?: any) {
            return GamesApiFp(configuration).v1GamesSortsGet(modelGameSortsContext, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the game icon
         * @param {number} universeId The id of the universe to get the icon for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdIconGet(universeId: number, options?: any) {
            return GamesApiFp(configuration).v1GamesUniverseIdIconGet(universeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the game media data
         * @param {number} universeId The id of the universe we get media data from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdMediaGet(universeId: number, options?: any) {
            return GamesApiFp(configuration).v1GamesUniverseIdMediaGet(universeId, options)(axios, basePath);
        },
    };
};

/**
 * GamesApi - interface
 * @export
 * @interface GamesApi
 */
export interface GamesApiInterface {
    /**
     * 
     * @summary Get a single game thumbnail
     * @param {string} imageToken 
     * @param {number} [height] 
     * @param {number} [width] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesGameThumbnailGet(imageToken: string, height?: number, width?: number, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Gets a list of game thumbnails
     * @param {Array<string>} imageTokens 
     * @param {number} [height] 
     * @param {number} [width] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesGameThumbnailsGet(imageTokens: Array<string>, height?: number, width?: number, options?: any): AxiosPromise<Array<RobloxGamesApiModelsResponseThumbnail>>;

    /**
     * 
     * @summary Gets a list of games\' product info, used to purchase a game
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesGamesProductInfoGet(universeIds: Array<number>, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameProductResponse>;

    /**
     * 
     * @summary Gets a list of games\' detail
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesGet(universeIds: Array<number>, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameDetailResponse>;

    /**
     * 
     * @summary Gets a list of games
     * @param {string} [modelSortToken] 
     * @param {string} [modelGameFilter] 
     * @param {string} [modelTimeFilter] 
     * @param {string} [modelGenreFilter] 
     * @param {number} [modelExclusiveStartId] 
     * @param {number} [modelSortOrder] 
     * @param {number} [modelGameSetTargetId] 
     * @param {string} [modelKeyword] 
     * @param {number} [modelStartRows] 
     * @param {number} [modelMaxRows] 
     * @param {boolean} [modelIsKeywordSuggestionEnabled] 
     * @param {number} [modelContextCountryRegionId] 
     * @param {number} [modelContextUniverseId] 
     * @param {string} [modelPageId] 
     * @param {number} [modelSortPosition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesListGet(modelSortToken?: string, modelGameFilter?: string, modelTimeFilter?: string, modelGenreFilter?: string, modelExclusiveStartId?: number, modelSortOrder?: number, modelGameSetTargetId?: number, modelKeyword?: string, modelStartRows?: number, modelMaxRows?: number, modelIsKeywordSuggestionEnabled?: boolean, modelContextCountryRegionId?: number, modelContextUniverseId?: number, modelPageId?: string, modelSortPosition?: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGamesSearchResponse>;

    /**
     * 
     * @summary Get place details
     * @param {Array<number>} placeIds List of placeId to uniquely Identify a place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesMultigetPlaceDetailsGet(placeIds: Array<number>, options?: any): AxiosPromise<Array<RobloxGamesApiModelsResponsePlaceDetails>>;

    /**
     * 
     * @summary Gets a list of universe playability statuses for the authenticated user
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesMultigetPlayabilityStatusGet(universeIds: Array<number>, options?: any): AxiosPromise<Array<RobloxGamesApiModelsResponsePlayabilityStatusResponse>>;

    /**
     * 
     * @summary Get the game server list
     * @param {number} placeId The Id of the place we are geting the server list for.
     * @param {'Public' | 'Friend' | 'VIP'} serverType The type of the server we geting the server list for.
     * @param {'Asc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesPlaceIdServersServerTypeGet(placeId: number, serverType: 'Public' | 'Friend' | 'VIP', sortOrder?: 'Asc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiPageResponseRobloxWebResponsesGamesGameServerResponse>;

    /**
     * 
     * @summary Used by the GameServer to set a place\'s metadata, including whether it is filtering enabled.
     * @param {number} placeId The Id of the place we are setting the metadata for.
     * @param {RobloxGamesApiReportPlaceMetadataRequest} request An {Roblox.Games.Api.ReportPlaceMetadataRequest}.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesPlacesPlaceIdMetadataPost(placeId: number, request: RobloxGamesApiReportPlaceMetadataRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Get games recommendations
     * @param {string} algorithmName The algorithm name of recommendations
     * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
     * @param {number} [modelMaxRows] The requested number of rows.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName: string, modelPaginationKey?: string, modelMaxRows?: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameRecommendationsResponse>;

    /**
     * 
     * @summary Get games recommendations based on a given universe
     * @param {number} universeId The universe to base recommendations on
     * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
     * @param {number} [modelMaxRows] The requested number of rows.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesRecommendationsGameUniverseIdGet(universeId: number, modelPaginationKey?: string, modelMaxRows?: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameRecommendationsResponse>;

    /**
     * 
     * @summary Gets an ordered list of all sorts
     * @param {'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2'} [modelGameSortsContext] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesSortsGet(modelGameSortsContext?: 'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2', options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameSortsResponse>;

    /**
     * 
     * @summary Get the game icon
     * @param {number} universeId The id of the universe to get the icon for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesUniverseIdIconGet(universeId: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameIconResponse>;

    /**
     * 
     * @summary Get the game media data
     * @param {number} universeId The id of the universe we get media data from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApiInterface
     */
    v1GamesUniverseIdMediaGet(universeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameMediaItem>;

}

/**
 * GamesApi - object-oriented interface
 * @export
 * @class GamesApi
 * @extends {BaseAPI}
 */
export class GamesApi extends BaseAPI implements GamesApiInterface {
    /**
     * 
     * @summary Get a single game thumbnail
     * @param {string} imageToken 
     * @param {number} [height] 
     * @param {number} [width] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesGameThumbnailGet(imageToken: string, height?: number, width?: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesGameThumbnailGet(imageToken, height, width, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of game thumbnails
     * @param {Array<string>} imageTokens 
     * @param {number} [height] 
     * @param {number} [width] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesGameThumbnailsGet(imageTokens: Array<string>, height?: number, width?: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesGameThumbnailsGet(imageTokens, height, width, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of games\' product info, used to purchase a game
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesGamesProductInfoGet(universeIds: Array<number>, options?: any) {
        return GamesApiFp(this.configuration).v1GamesGamesProductInfoGet(universeIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of games\' detail
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesGet(universeIds: Array<number>, options?: any) {
        return GamesApiFp(this.configuration).v1GamesGet(universeIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of games
     * @param {string} [modelSortToken] 
     * @param {string} [modelGameFilter] 
     * @param {string} [modelTimeFilter] 
     * @param {string} [modelGenreFilter] 
     * @param {number} [modelExclusiveStartId] 
     * @param {number} [modelSortOrder] 
     * @param {number} [modelGameSetTargetId] 
     * @param {string} [modelKeyword] 
     * @param {number} [modelStartRows] 
     * @param {number} [modelMaxRows] 
     * @param {boolean} [modelIsKeywordSuggestionEnabled] 
     * @param {number} [modelContextCountryRegionId] 
     * @param {number} [modelContextUniverseId] 
     * @param {string} [modelPageId] 
     * @param {number} [modelSortPosition] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesListGet(modelSortToken?: string, modelGameFilter?: string, modelTimeFilter?: string, modelGenreFilter?: string, modelExclusiveStartId?: number, modelSortOrder?: number, modelGameSetTargetId?: number, modelKeyword?: string, modelStartRows?: number, modelMaxRows?: number, modelIsKeywordSuggestionEnabled?: boolean, modelContextCountryRegionId?: number, modelContextUniverseId?: number, modelPageId?: string, modelSortPosition?: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesListGet(modelSortToken, modelGameFilter, modelTimeFilter, modelGenreFilter, modelExclusiveStartId, modelSortOrder, modelGameSetTargetId, modelKeyword, modelStartRows, modelMaxRows, modelIsKeywordSuggestionEnabled, modelContextCountryRegionId, modelContextUniverseId, modelPageId, modelSortPosition, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get place details
     * @param {Array<number>} placeIds List of placeId to uniquely Identify a place
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesMultigetPlaceDetailsGet(placeIds: Array<number>, options?: any) {
        return GamesApiFp(this.configuration).v1GamesMultigetPlaceDetailsGet(placeIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of universe playability statuses for the authenticated user
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesMultigetPlayabilityStatusGet(universeIds: Array<number>, options?: any) {
        return GamesApiFp(this.configuration).v1GamesMultigetPlayabilityStatusGet(universeIds, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the game server list
     * @param {number} placeId The Id of the place we are geting the server list for.
     * @param {'Public' | 'Friend' | 'VIP'} serverType The type of the server we geting the server list for.
     * @param {'Asc'} [sortOrder] The order the results are sorted in.
     * @param {10 | 25 | 50 | 100} [limit] The amount of results per request.
     * @param {string} [cursor] The paging cursor for the previous or next page.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesPlaceIdServersServerTypeGet(placeId: number, serverType: 'Public' | 'Friend' | 'VIP', sortOrder?: 'Asc', limit?: 10 | 25 | 50 | 100, cursor?: string, options?: any) {
        return GamesApiFp(this.configuration).v1GamesPlaceIdServersServerTypeGet(placeId, serverType, sortOrder, limit, cursor, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Used by the GameServer to set a place\'s metadata, including whether it is filtering enabled.
     * @param {number} placeId The Id of the place we are setting the metadata for.
     * @param {RobloxGamesApiReportPlaceMetadataRequest} request An {Roblox.Games.Api.ReportPlaceMetadataRequest}.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesPlacesPlaceIdMetadataPost(placeId: number, request: RobloxGamesApiReportPlaceMetadataRequest, options?: any) {
        return GamesApiFp(this.configuration).v1GamesPlacesPlaceIdMetadataPost(placeId, request, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get games recommendations
     * @param {string} algorithmName The algorithm name of recommendations
     * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
     * @param {number} [modelMaxRows] The requested number of rows.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName: string, modelPaginationKey?: string, modelMaxRows?: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesRecommendationsAlgorithmAlgorithmNameGet(algorithmName, modelPaginationKey, modelMaxRows, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get games recommendations based on a given universe
     * @param {number} universeId The universe to base recommendations on
     * @param {string} [modelPaginationKey] The key of a page, which includes the start row index and all other necessary information to query the data.  This parameter is usually not needed for the first page.
     * @param {number} [modelMaxRows] The requested number of rows.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesRecommendationsGameUniverseIdGet(universeId: number, modelPaginationKey?: string, modelMaxRows?: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesRecommendationsGameUniverseIdGet(universeId, modelPaginationKey, modelMaxRows, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets an ordered list of all sorts
     * @param {'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2'} [modelGameSortsContext] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesSortsGet(modelGameSortsContext?: 'GamesDefaultSorts' | 'GamesAllSorts' | 'HomeSorts' | 'ChatSorts' | 'UnifiedHomeSorts' | 'AbTestSorts' | 'GamesPageAbTestSorts1' | 'GamesPageAbTestSorts2', options?: any) {
        return GamesApiFp(this.configuration).v1GamesSortsGet(modelGameSortsContext, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the game icon
     * @param {number} universeId The id of the universe to get the icon for.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesUniverseIdIconGet(universeId: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesUniverseIdIconGet(universeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the game media data
     * @param {number} universeId The id of the universe we get media data from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GamesApi
     */
    public v1GamesUniverseIdMediaGet(universeId: number, options?: any) {
        return GamesApiFp(this.configuration).v1GamesUniverseIdMediaGet(universeId, options)(this.axios, this.basePath);
    }

}


/**
 * SocialLinksApi - axios parameter creator
 * @export
 */
export const SocialLinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get social link data associated with a game
         * @param {number} universeId The Id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdSocialLinksListGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdSocialLinksListGet.');
            }
            const localVarPath = `/v1/games/{universeId}/social-links/list`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SocialLinksApi - functional programming interface
 * @export
 */
export const SocialLinksApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get social link data associated with a game
         * @param {number} universeId The Id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdSocialLinksListGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiSocialLinkResponse> {
            const localVarAxiosArgs = SocialLinksApiAxiosParamCreator(configuration).v1GamesUniverseIdSocialLinksListGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SocialLinksApi - factory interface
 * @export
 */
export const SocialLinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Get social link data associated with a game
         * @param {number} universeId The Id of the game
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdSocialLinksListGet(universeId: number, options?: any) {
            return SocialLinksApiFp(configuration).v1GamesUniverseIdSocialLinksListGet(universeId, options)(axios, basePath);
        },
    };
};

/**
 * SocialLinksApi - interface
 * @export
 * @interface SocialLinksApi
 */
export interface SocialLinksApiInterface {
    /**
     * 
     * @summary Get social link data associated with a game
     * @param {number} universeId The Id of the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialLinksApiInterface
     */
    v1GamesUniverseIdSocialLinksListGet(universeId: number, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiSocialLinkResponse>;

}

/**
 * SocialLinksApi - object-oriented interface
 * @export
 * @class SocialLinksApi
 * @extends {BaseAPI}
 */
export class SocialLinksApi extends BaseAPI implements SocialLinksApiInterface {
    /**
     * 
     * @summary Get social link data associated with a game
     * @param {number} universeId The Id of the game
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SocialLinksApi
     */
    public v1GamesUniverseIdSocialLinksListGet(universeId: number, options?: any) {
        return SocialLinksApiFp(this.configuration).v1GamesUniverseIdSocialLinksListGet(universeId, options)(this.axios, this.basePath);
    }

}


/**
 * VipServersApi - axios parameter creator
 * @export
 */
export const VipServersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create VIP server for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiCreateVipServersRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVipServersUniverseIdPost(universeId: number, requestBody: RobloxGamesApiCreateVipServersRequest, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesVipServersUniverseIdPost.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1GamesVipServersUniverseIdPost.');
            }
            const localVarPath = `/v1/games/vip-servers/{universeId}`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiCreateVipServersRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get necessary data to generate webpage
         * @param {number} id The VIP Server ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdGet(id: number, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VipServersIdGet.');
            }
            const localVarPath = `/v1/vip-servers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateRequest} request The {Roblox.Games.Api.VipServerUpdateRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPatch(id: number, request: RobloxGamesApiVipServerUpdateRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VipServersIdPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1VipServersIdPatch.');
            }
            const localVarPath = `/v1/vip-servers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiVipServerUpdateRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update friend/clan access and allowed friends/clan list
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdatePermissionsRequest} request The {Roblox.Games.Api.VipServerUpdatePermissionsRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPermissionsPatch(id: number, request: RobloxGamesApiVipServerUpdatePermissionsRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VipServersIdPermissionsPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1VipServersIdPermissionsPatch.');
            }
            const localVarPath = `/v1/vip-servers/{id}/permissions`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiVipServerUpdatePermissionsRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates subscription status of a vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateSubscriptionRequest} request The {Roblox.Games.Api.VipServerUpdateSubscriptionRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdSubscriptionPatch(id: number, request: RobloxGamesApiVipServerUpdateSubscriptionRequest, options: any = {}): RequestArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling v1VipServersIdSubscriptionPatch.');
            }
            // verify required parameter 'request' is not null or undefined
            if (request === null || request === undefined) {
                throw new RequiredError('request','Required parameter request was null or undefined when calling v1VipServersIdSubscriptionPatch.');
            }
            const localVarPath = `/v1/vip-servers/{id}/subscription`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiVipServerUpdateSubscriptionRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(request !== undefined ? request : {}) : (request || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VipServersApi - functional programming interface
 * @export
 */
export const VipServersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create VIP server for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiCreateVipServersRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVipServersUniverseIdPost(universeId: number, requestBody: RobloxGamesApiCreateVipServersRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebResponsesGamesGameServerResponse> {
            const localVarAxiosArgs = VipServersApiAxiosParamCreator(configuration).v1GamesVipServersUniverseIdPost(universeId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get necessary data to generate webpage
         * @param {number} id The VIP Server ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdGet(id: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiVipServerResponse> {
            const localVarAxiosArgs = VipServersApiAxiosParamCreator(configuration).v1VipServersIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateRequest} request The {Roblox.Games.Api.VipServerUpdateRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPatch(id: number, request: RobloxGamesApiVipServerUpdateRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiVipServerResponse> {
            const localVarAxiosArgs = VipServersApiAxiosParamCreator(configuration).v1VipServersIdPatch(id, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Update friend/clan access and allowed friends/clan list
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdatePermissionsRequest} request The {Roblox.Games.Api.VipServerUpdatePermissionsRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPermissionsPatch(id: number, request: RobloxGamesApiVipServerUpdatePermissionsRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiVipServerPermissionsResponse> {
            const localVarAxiosArgs = VipServersApiAxiosParamCreator(configuration).v1VipServersIdPermissionsPatch(id, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Updates subscription status of a vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateSubscriptionRequest} request The {Roblox.Games.Api.VipServerUpdateSubscriptionRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdSubscriptionPatch(id: number, request: RobloxGamesApiVipServerUpdateSubscriptionRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiVipServerSubscriptionResponse> {
            const localVarAxiosArgs = VipServersApiAxiosParamCreator(configuration).v1VipServersIdSubscriptionPatch(id, request, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VipServersApi - factory interface
 * @export
 */
export const VipServersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Create VIP server for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiCreateVipServersRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVipServersUniverseIdPost(universeId: number, requestBody: RobloxGamesApiCreateVipServersRequest, options?: any) {
            return VipServersApiFp(configuration).v1GamesVipServersUniverseIdPost(universeId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get necessary data to generate webpage
         * @param {number} id The VIP Server ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdGet(id: number, options?: any) {
            return VipServersApiFp(configuration).v1VipServersIdGet(id, options)(axios, basePath);
        },
        /**
         * 
         * @summary Updates vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateRequest} request The {Roblox.Games.Api.VipServerUpdateRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPatch(id: number, request: RobloxGamesApiVipServerUpdateRequest, options?: any) {
            return VipServersApiFp(configuration).v1VipServersIdPatch(id, request, options)(axios, basePath);
        },
        /**
         * 
         * @summary Update friend/clan access and allowed friends/clan list
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdatePermissionsRequest} request The {Roblox.Games.Api.VipServerUpdatePermissionsRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdPermissionsPatch(id: number, request: RobloxGamesApiVipServerUpdatePermissionsRequest, options?: any) {
            return VipServersApiFp(configuration).v1VipServersIdPermissionsPatch(id, request, options)(axios, basePath);
        },
        /**
         * 
         * @summary Updates subscription status of a vip server
         * @param {number} id The VIP Server ID
         * @param {RobloxGamesApiVipServerUpdateSubscriptionRequest} request The {Roblox.Games.Api.VipServerUpdateSubscriptionRequest}
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1VipServersIdSubscriptionPatch(id: number, request: RobloxGamesApiVipServerUpdateSubscriptionRequest, options?: any) {
            return VipServersApiFp(configuration).v1VipServersIdSubscriptionPatch(id, request, options)(axios, basePath);
        },
    };
};

/**
 * VipServersApi - interface
 * @export
 * @interface VipServersApi
 */
export interface VipServersApiInterface {
    /**
     * 
     * @summary Create VIP server for a game
     * @param {number} universeId The id of the universe.
     * @param {RobloxGamesApiCreateVipServersRequest} requestBody The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApiInterface
     */
    v1GamesVipServersUniverseIdPost(universeId: number, requestBody: RobloxGamesApiCreateVipServersRequest, options?: any): AxiosPromise<RobloxWebResponsesGamesGameServerResponse>;

    /**
     * 
     * @summary Get necessary data to generate webpage
     * @param {number} id The VIP Server ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApiInterface
     */
    v1VipServersIdGet(id: number, options?: any): AxiosPromise<RobloxGamesApiVipServerResponse>;

    /**
     * 
     * @summary Updates vip server
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdateRequest} request The {Roblox.Games.Api.VipServerUpdateRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApiInterface
     */
    v1VipServersIdPatch(id: number, request: RobloxGamesApiVipServerUpdateRequest, options?: any): AxiosPromise<RobloxGamesApiVipServerResponse>;

    /**
     * 
     * @summary Update friend/clan access and allowed friends/clan list
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdatePermissionsRequest} request The {Roblox.Games.Api.VipServerUpdatePermissionsRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApiInterface
     */
    v1VipServersIdPermissionsPatch(id: number, request: RobloxGamesApiVipServerUpdatePermissionsRequest, options?: any): AxiosPromise<RobloxGamesApiVipServerPermissionsResponse>;

    /**
     * 
     * @summary Updates subscription status of a vip server
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdateSubscriptionRequest} request The {Roblox.Games.Api.VipServerUpdateSubscriptionRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApiInterface
     */
    v1VipServersIdSubscriptionPatch(id: number, request: RobloxGamesApiVipServerUpdateSubscriptionRequest, options?: any): AxiosPromise<RobloxGamesApiVipServerSubscriptionResponse>;

}

/**
 * VipServersApi - object-oriented interface
 * @export
 * @class VipServersApi
 * @extends {BaseAPI}
 */
export class VipServersApi extends BaseAPI implements VipServersApiInterface {
    /**
     * 
     * @summary Create VIP server for a game
     * @param {number} universeId The id of the universe.
     * @param {RobloxGamesApiCreateVipServersRequest} requestBody The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApi
     */
    public v1GamesVipServersUniverseIdPost(universeId: number, requestBody: RobloxGamesApiCreateVipServersRequest, options?: any) {
        return VipServersApiFp(this.configuration).v1GamesVipServersUniverseIdPost(universeId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get necessary data to generate webpage
     * @param {number} id The VIP Server ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApi
     */
    public v1VipServersIdGet(id: number, options?: any) {
        return VipServersApiFp(this.configuration).v1VipServersIdGet(id, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Updates vip server
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdateRequest} request The {Roblox.Games.Api.VipServerUpdateRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApi
     */
    public v1VipServersIdPatch(id: number, request: RobloxGamesApiVipServerUpdateRequest, options?: any) {
        return VipServersApiFp(this.configuration).v1VipServersIdPatch(id, request, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Update friend/clan access and allowed friends/clan list
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdatePermissionsRequest} request The {Roblox.Games.Api.VipServerUpdatePermissionsRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApi
     */
    public v1VipServersIdPermissionsPatch(id: number, request: RobloxGamesApiVipServerUpdatePermissionsRequest, options?: any) {
        return VipServersApiFp(this.configuration).v1VipServersIdPermissionsPatch(id, request, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Updates subscription status of a vip server
     * @param {number} id The VIP Server ID
     * @param {RobloxGamesApiVipServerUpdateSubscriptionRequest} request The {Roblox.Games.Api.VipServerUpdateSubscriptionRequest}
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VipServersApi
     */
    public v1VipServersIdSubscriptionPatch(id: number, request: RobloxGamesApiVipServerUpdateSubscriptionRequest, options?: any) {
        return VipServersApiFp(this.configuration).v1VipServersIdSubscriptionPatch(id, request, options)(this.axios, this.basePath);
    }

}


/**
 * VotesApi - axios parameter creator
 * @export
 */
export const VotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set the user\'s vote for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiModelsRequestSetUserGameVoteRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdUserVotesPatch(universeId: number, requestBody: RobloxGamesApiModelsRequestSetUserGameVoteRequest, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdUserVotesPatch.');
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError('requestBody','Required parameter requestBody was null or undefined when calling v1GamesUniverseIdUserVotesPatch.');
            }
            const localVarPath = `/v1/games/{universeId}/user-votes`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};
            const needsSerialization = (<any>"RobloxGamesApiModelsRequestSetUserGameVoteRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(requestBody !== undefined ? requestBody : {}) : (requestBody || "");

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the game vote status
         * @param {number} universeId The id of the universe we get vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdVotesGet.');
            }
            const localVarPath = `/v1/games/{universeId}/votes`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get the user\'s vote status for a game
         * @param {number} universeId The id of the universe we get user vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesUserGet(universeId: number, options: any = {}): RequestArgs {
            // verify required parameter 'universeId' is not null or undefined
            if (universeId === null || universeId === undefined) {
                throw new RequiredError('universeId','Required parameter universeId was null or undefined when calling v1GamesUniverseIdVotesUserGet.');
            }
            const localVarPath = `/v1/games/{universeId}/votes/user`
                .replace(`{${"universeId"}}`, encodeURIComponent(String(universeId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Gets a list of universe vote status
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVotesGet(universeIds: Array<number>, options: any = {}): RequestArgs {
            // verify required parameter 'universeIds' is not null or undefined
            if (universeIds === null || universeIds === undefined) {
                throw new RequiredError('universeIds','Required parameter universeIds was null or undefined when calling v1GamesVotesGet.');
            }
            const localVarPath = `/v1/games/votes`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (universeIds) {
                localVarQueryParameter['universeIds'] = universeIds.join(COLLECTION_FORMATS.csv);
            }


                localVarUrlObj.query = {...localVarUrlObj.query, ...localVarQueryParameter, ...options.query};
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...options.headers};

            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VotesApi - functional programming interface
 * @export
 */
export const VotesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Set the user\'s vote for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiModelsRequestSetUserGameVoteRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdUserVotesPatch(universeId: number, requestBody: RobloxGamesApiModelsRequestSetUserGameVoteRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<object> {
            const localVarAxiosArgs = VotesApiAxiosParamCreator(configuration).v1GamesUniverseIdUserVotesPatch(universeId, requestBody, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the game vote status
         * @param {number} universeId The id of the universe we get vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseGameVoteResponse> {
            const localVarAxiosArgs = VotesApiAxiosParamCreator(configuration).v1GamesUniverseIdVotesGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Get the user\'s vote status for a game
         * @param {number} universeId The id of the universe we get user vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesUserGet(universeId: number, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxGamesApiModelsResponseUserGameVoteResponse> {
            const localVarAxiosArgs = VotesApiAxiosParamCreator(configuration).v1GamesUniverseIdVotesUserGet(universeId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @summary Gets a list of universe vote status
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVotesGet(universeIds: Array<number>, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameVoteResponse> {
            const localVarAxiosArgs = VotesApiAxiosParamCreator(configuration).v1GamesVotesGet(universeIds, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * VotesApi - factory interface
 * @export
 */
export const VotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Set the user\'s vote for a game
         * @param {number} universeId The id of the universe.
         * @param {RobloxGamesApiModelsRequestSetUserGameVoteRequest} requestBody The request body.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdUserVotesPatch(universeId: number, requestBody: RobloxGamesApiModelsRequestSetUserGameVoteRequest, options?: any) {
            return VotesApiFp(configuration).v1GamesUniverseIdUserVotesPatch(universeId, requestBody, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the game vote status
         * @param {number} universeId The id of the universe we get vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesGet(universeId: number, options?: any) {
            return VotesApiFp(configuration).v1GamesUniverseIdVotesGet(universeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get the user\'s vote status for a game
         * @param {number} universeId The id of the universe we get user vote status from.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesUniverseIdVotesUserGet(universeId: number, options?: any) {
            return VotesApiFp(configuration).v1GamesUniverseIdVotesUserGet(universeId, options)(axios, basePath);
        },
        /**
         * 
         * @summary Gets a list of universe vote status
         * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1GamesVotesGet(universeIds: Array<number>, options?: any) {
            return VotesApiFp(configuration).v1GamesVotesGet(universeIds, options)(axios, basePath);
        },
    };
};

/**
 * VotesApi - interface
 * @export
 * @interface VotesApi
 */
export interface VotesApiInterface {
    /**
     * 
     * @summary Set the user\'s vote for a game
     * @param {number} universeId The id of the universe.
     * @param {RobloxGamesApiModelsRequestSetUserGameVoteRequest} requestBody The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApiInterface
     */
    v1GamesUniverseIdUserVotesPatch(universeId: number, requestBody: RobloxGamesApiModelsRequestSetUserGameVoteRequest, options?: any): AxiosPromise<object>;

    /**
     * 
     * @summary Get the game vote status
     * @param {number} universeId The id of the universe we get vote status from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApiInterface
     */
    v1GamesUniverseIdVotesGet(universeId: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseGameVoteResponse>;

    /**
     * 
     * @summary Get the user\'s vote status for a game
     * @param {number} universeId The id of the universe we get user vote status from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApiInterface
     */
    v1GamesUniverseIdVotesUserGet(universeId: number, options?: any): AxiosPromise<RobloxGamesApiModelsResponseUserGameVoteResponse>;

    /**
     * 
     * @summary Gets a list of universe vote status
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApiInterface
     */
    v1GamesVotesGet(universeIds: Array<number>, options?: any): AxiosPromise<RobloxWebWebAPIModelsApiArrayResponseRobloxGamesApiModelsResponseGameVoteResponse>;

}

/**
 * VotesApi - object-oriented interface
 * @export
 * @class VotesApi
 * @extends {BaseAPI}
 */
export class VotesApi extends BaseAPI implements VotesApiInterface {
    /**
     * 
     * @summary Set the user\'s vote for a game
     * @param {number} universeId The id of the universe.
     * @param {RobloxGamesApiModelsRequestSetUserGameVoteRequest} requestBody The request body.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApi
     */
    public v1GamesUniverseIdUserVotesPatch(universeId: number, requestBody: RobloxGamesApiModelsRequestSetUserGameVoteRequest, options?: any) {
        return VotesApiFp(this.configuration).v1GamesUniverseIdUserVotesPatch(universeId, requestBody, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the game vote status
     * @param {number} universeId The id of the universe we get vote status from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApi
     */
    public v1GamesUniverseIdVotesGet(universeId: number, options?: any) {
        return VotesApiFp(this.configuration).v1GamesUniverseIdVotesGet(universeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get the user\'s vote status for a game
     * @param {number} universeId The id of the universe we get user vote status from.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApi
     */
    public v1GamesUniverseIdVotesUserGet(universeId: number, options?: any) {
        return VotesApiFp(this.configuration).v1GamesUniverseIdVotesUserGet(universeId, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Gets a list of universe vote status
     * @param {Array<number>} universeIds A list of universe Ids. Cannot exceed a maximum of 100 IDs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VotesApi
     */
    public v1GamesVotesGet(universeIds: Array<number>, options?: any) {
        return VotesApiFp(this.configuration).v1GamesVotesGet(universeIds, options)(this.axios, this.basePath);
    }

}


